.fullPage {
    background: #FFFFFF;
}
h3 {
    display: block;
    margin: 25px auto;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    padding: 0 30px;

    @media only screen and (max-width: 768px) {
        font-size: 24px;
    }
}

.container {
    display: block;
    width: 90%;
    margin: 0 auto;

    h2 {
        display: block;
        margin: 25px auto;
        font-size: 32px;
        font-weight: bold;

        @media only screen and (max-width: 768px) {
            font-size: 24px;
        }
    }
    p{
        font-size: 16px;
        color: #333333;
    }
}
.reasonsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: 0 auto;
    h3 {
        font-size: 32px;
        color: #333333;
        line-height: 1.3;
        font-weight: 500;
    }
    .reasons{
        width: 100%;
        display: flex;
        flex-direction:row;
        margin: 30px 0;
        justify-content: center;


        .reason{
            width: calc(33% - 30px);
            margin: 0 15px;

            .iconContainer{
                min-height: 126px;
                min-width: 134px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
            }
            p{
                font-size: 16px;
                color:#000;
                line-height: normal;
                font-weight: 400;
            }

            h3{
                font-size: 24px;
                min-height: 122px;
                color: #333333;
                font-weight: 400;

                @media only screen and (max-width: 1100px) {
                    font-size: 24px;
                }
            }

            small{
                display: inline-block;
                font-weight: 400;

            }
        }

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            margin: 0;

            .reason{
                width: 90%;
                margin: 10px 0;
                h3{
                    min-height: 0;
                    width: 80%;
                }
                p{
                    max-width: 600px;
                }
            }
        }
    }
}

.formSection {
    width: 100%;
    display: inline-block;
    background: #FFFDFB;
    @media only screen and (max-width: 768px) {
        padding: 0 10px;
    }
    h2 {
        width: 100%;
        display: block;
        margin: 30px auto;
        text-align: center;
        font-weight: 600;
        padding: 30px 0;
        @media only screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    > p {
        display: block;
        margin: 30px auto;
        text-align: center;
        max-width: 1000px;
        padding: 0 20px;
        color: #333333;
    }
}

.container {
    display: block;
    width: 90%;
    margin: 0 auto;

    h2 {
        display: block;
        margin: 25px auto;
        font-size: 28px;
        font-weight: 700;
        max-width: 700px;

        @media only screen and (max-width: 768px) {
            font-size: 24px;
        }
    }
    p{
        font-size: 16px;
        color: #333333;
    }
}
.directive {
    display: block;
    margin: 80px auto 0 auto;
    @media only screen and (max-width: 768px) {
        max-width: 285px;
    }
}
.benefitsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;

    .reasons{
        width: 100%;
        display: flex;
        flex-direction:row;
        margin: 30px 0;
        justify-content: center;

        .reason{
            width: calc(33% - 30px);
            margin: 0 15px;
            &:nth-child(2) {
                margin: 0;
            }

            .iconContainer{
                min-height: 126px;
                min-width: 134px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
            }
            p{
                font-size: 16px;
                color:#000;
                line-height: normal;
                max-width: 350px;
            }

            h3{
                font-size: 28px;
                color: #333333;
                margin: 30px auto;
                font-weight: 400;

                @media only screen and (max-width: 1100px) {
                    font-size: 24px;
                }
            }

            small{
                display: inline-block;
            }
        }

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            margin: 0;

            .reason{
                width: 90%;
                margin: 30px 0;
                h3{
                    min-height: 0;
                    width: 100%;
                }
                p{
                    max-width: 600px;
                }
            }
        }
    }
}
