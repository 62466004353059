.footer {
    font-family: 'Noto Sans';
    font-weight: 500;

    display: inline-block;
    position: relative;
    background: #fff;
    width: 100%;

    .container {
        max-width: 90%;
    }
    .containerPWA {
        display: inline-block;
        position: relative;
        margin-top: -25px;
        background: #fff;
        width: 100%;

        @media only screen and (max-width: 330px) {
            width: 100%;
        }
    }

    > div {
        width: 100%;
    }

    // Logo
    a.logo {
        background-image: url('../../assets/images/ui/logo/logo_footer.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: inline-block;
        text-indent: -9999px;
        width: 138px;
        height: 36px;
        float: left;

        margin-top: 5px;

        @media only screen and (max-width: 1024px) {
            width: 90px;
            height: 27px;
        }
    }

    // Hints toggle
    .toggleWrapper {
        display: inline-block;
        margin-top: 25px;
        float: left;
        clear: both;

        &.active {
            margin-bottom: 25px;
            z-index: 10;
        }

        p {
            color: #838383;
            width: auto;
            float: left;

            margin-right: 15px;
        }

        input.mobileToggle + label {
            margin-top: 2px;
        }

        @media only screen and (max-width: 1300px) {
            input.mobileToggle + label {
                margin-top: 5px;
                margin-left: 0;
            }
        }

        @media only screen and (max-width: 810px) {
            margin-top: 15px;
            float: none;
            clear: both;

            @media only screen and (min-width: 400px) {
                > p {
                    margin-left: 0 !important;
                }
            }
        }
    }

    // Copyright
    .details {
        margin-left: 25px;
        margin-top: 25px;
        text-align: left;
        float: left;

        @media only screen and (max-width: 1024px) {
            width: calc(45% - 25px);
        }

        p {
            color: #999;
            clear: both;
            float: left;
            width: auto;

            letter-spacing: 0.1px;
            line-height: 20px;
            font-size: 16px;

            @media only screen and (max-width: 1024px) {
                letter-spacing: normal;
                line-height: 15px;
                font-size: 12px;
                width: 100%;
            }
        }
    }

    // Menu
    ul.columns {
        display: inline-block;
        text-align: left;
        list-style: none;
        font-size: 16px;
        float: right;
        padding: 0;

        width: auto;
        margin-top: 25px;
        margin-bottom: 25px;

        > li {
            display: inline-block;
            width: 225px;
            float: left;

            &:not(:last-child){ margin-right: 15px; }

            &:not(.legal) {
                width: 150px;
            }
            &.always-active {
                width: 150px;
            }

            ul {
                display: inline-block;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 5px;
                    font-size: 14px;
                    width: 100%;

                    &:first-child{
                        font-weight: bold;
                        margin-bottom: 8px;
                    }

                    a, button {
                        background: none;
                        cursor: pointer;
                        outline: none;
                        border: none;

                        font-size: 16px;
                        opacity: 0.5;
                        color: #000;
                        padding: 0;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &.social_links {
                    li {
                        margin-right: 15px;
                        text-align: center;
                        width: 100%;

                        &:first-child {
                            font-weight: bold;
                            width: 100%;
                        }

                        a {
                            text-decoration: none;
                            font-size: 25px;
                            color: #33393f;

                            @media only screen and (max-width: 570px) {
                                font-size: 25px;
                            }

                            &:hover {
                                text-decoration: none;
                                //color: $gold;
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 1024px) {
            width: 50%;

            li {
                margin-bottom: 0px !important;
                width: 100% !important;
                margin-right: 15px;
                float: right;

                ul {
                    text-align: right;
                    width: 100%;

                    &.social_links {
                        li {
                            text-align: right;
                            width: auto !important;

                            // eslint-disable-next-line
                            &:first-child {
                                width: 100% !important;
                            }

                            &:nth-child(3) {
                                margin-right: 15px !important;
                            }
                        }
                    }

                    li {
                        font-size: 13px;
                        cursor: pointer;

                        &:not(:first-child) {
                            margin-right: 45px;
                            display: none;
                        }

                        // eslint-disable-next-line
                        &:first-child {
                            &:after {
                                //@include font-awesome();
                                margin-left: 15px;
                                content: '\f078';
                            }
                        }

                        a {
                            font-size: 14px;
                            line-height: 25px;
                        }
                    }
                }

                &.is-active {
                    margin-bottom: 25px;

                    li {
                        display: inline-block !important;

                        // eslint-disable-next-line
                        &:first-child {
                            margin-bottom: 10px !important;

                            &:after {
                                content: '\f077';
                            }
                        }
                    }
                }
            }
        }
    }

    &.showCoachmarks {
        .details {
            border-bottom: none;
            position: relative;
            z-index: 6;

            a.logo {
                display: none;
            }

            > p {
                display: none;
            }
        }

        ul.columns {
            display: none;
        }
    }
}

.footerDetails {
    display: inline-block;
    padding: 25px 25px 0 0;

    margin-bottom: 15px;
    text-align: right;
    float: right;
    width: 50%;
}

.Collapsible {
    display: inline-block;
    text-align: right;
    float: right;
    clear: both;
    width: 100%;

    cursor: pointer;


    span {
        display: inline-block;
        margin-bottom: 5px;
        text-align: right;
        width: 100%;

        font-family: 'Source Sans Pro';
        font-weight: 700;

        &:after {
            font-family: FontAwesome;
            text-decoration: inherit;
            font-weight: 400;
            font-style: normal;
            margin-left: 15px;
            content: '\F078';
        }
    }
}

.CollapsibleTrigger {
    font-family: 'Source Sans Pro';
    text-align: right;
    font-weight: 700;
    cursor: pointer;
    width: 100%;

    &:after {
        font-family: FontAwesome;
        text-decoration: inherit;
        font-weight: 400;
        font-style: normal;
        margin-left: 15px;
        content: '\F077';
    }
}

.outerWrapper {
    display: inline-block;
    text-align: right;
    width: 100%;
    float: left;

    a {
        display: inline-block;
        text-align: left;
        width: 100%;
    }
}

.innerWrapper {
    margin-bottom: 10px;
    text-align: right;
    width: 100%;
    li {
        display: inline-block;
        width: 100%;
    }
    a, button {
        background: none;
        outline: none;
        border: none;

        font-family: 'Source Sans Pro';
        text-align: right;
        line-height: 25px;
        font-size: 14px;
        opacity: 0.3;
        color: #000;

        padding-right: 27px;

        &:hover {
            opacity: 1;
        }
    }
}

.footerPWA {
    @extend .footer;

    -webkit-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.25);
}

.closePopup{
    display: inline-block;
    position: absolute;
    cursor: pointer;
    float: right;

    height: auto !important;
    width: 20px !important;
    margin: 0 !important;
    right: 0 !important;
    top: -25px !important;
}

.saveButton{
    display: inline-block;

    font-family: Source Sans Pro;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: normal;
    text-align: center;
    padding: 10px 15px;
    font-weight: 300;
    cursor: pointer;
    outline: none;
    color: #fff;

    border: 1px solid rgba(255,255,255, 0.5);
    background: rgba(0,0,0, 0.7);
    margin: 0 10px 10px 0;
    min-width: 150px;

    text-decoration: none;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
        supported by Chrome and Opera */


    &:after {
        transition: all 300ms ease;

        text-decoration: inherit;
        font-family: FontAwesome;
        font-weight: normal;
        font-style: normal;
        content: '\f178';

        position: relative;
        margin-left: 10px;
        font-size: 16px;
        color: #d6a76f;
        top: 1px;
    }

    &:hover {
        background: #d6a76f;
        border-color: #d6a76f;
        text-decoration: none;
        transition: all 300ms ease;

        -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.8) 30%, #000 50%, rgba(0, 0, 0, 0.8) 70%);
        animation: shine 2s infinite;
        -webkit-mask-size: 200%;

        &:after {
            color: #fff;
        }
    }

    @-webkit-keyframes shine {
        from {
            -webkit-mask-position: 150%;

            &:after {
                margin-left: 10px;
            }
        }

        to {
            -webkit-mask-position: -50%;

            &:after {
                margin-left: 15px;
            }
        }
    }

    @media only screen and (max-width: 420px) {
        line-height: normal;
        text-align: center;
        padding: 12px 17px;
        height: auto;

        margin-bottom: 15px;
        margin-right: 0;
        margin-left: 0;
    }

    @media only screen and (max-width: 320px) {
        margin-bottom: 15px;
        margin-right: 0;
        margin-left: 0;
        width: 100%;
    }
}
