.pageContainer{
    background: radial-gradient(#4D5062, #1F1E2F);
    width: 100%;
    height: calc(100vh - 235px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: white;
    padding: 50px 0;

    @media only screen and (max-width: 320px){
        padding: 30px 0;
    }

    img{
        display: inline-block;
        max-width: 281px;
        height: 36px;
        margin-bottom: 50px;

        @media only screen and (max-width: 320px){
            margin-bottom: 30px;
        }
    }

    h3{
        color:white;
        font-size: 24px;
        width: 90%;

        @media only screen and (max-width: 320px){
            font-size: 20px;
        }
    }

    p, .contact{
        color:white;
        width: 90%;
        max-width: 760px;
        font-weight: 300;
        font-size: 18px;
        b{
            color: #D6A76F;
        }
    }
    .contact{
        font-size: 16px;
    }
}