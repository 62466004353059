.columns {
    display: inline-block;
    width: 100%;

    .column {
        width: calc(25% - 20px);
        display: inline-block;
        margin: 0 10px 20px;
        text-align: left;
        float: left;
    }

    &.of-two {
        .column {
            width: calc((100% / 2) - 20px);
        }
    }

    &.of-three {
        .column {
            width: calc((100% / 3) - 20px);
        }
    }

    &.of-five {
        .column {
            width: calc((100% / 5) - 20px);
        }
    }

    &.of-six {
        .column {
            width: calc((100% / 6) - 20px);
        }
    }
}
