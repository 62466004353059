@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../assets/fonts/OpenSans/stylesheet.scss';

@import 'helpers/module.scss';
@import 'base/module.scss';
@import 'fonts/module.scss';
@import 'vendors/module.scss';
@import 'layout/module.scss';

html,
body {
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

.App {
    //font-family: 'Open Sans';
    height: 100%;
    width: 100%;

    &.isPWA{
        padding-bottom: 65px;

        @media only screen and (max-width: 330px) {
            padding-bottom: 50px;
        }
    }
}

.react-reveal {
    width: 100%;
}


.ant-calendar-header,
.ant-calendar-picker{
    width: 100%;

    > div{ width: 100%; }
}

.ant-calendar-input-wrap{ width: 100%; }
.ant-calendar-input{ text-align: center; }

.ant-input:hover{
    border-color: #d6a76f !important;
}

.ant-calendar-today .ant-calendar-date{
    color: #000 !important;
    font-weight: normal !important;
    border-color: #d6a76f !important;
}

.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before{ display: none; }

.ant-calendar-selected-day .ant-calendar-date{
    background: #d6a76f !important;
    color: #fff;
}

.ant-calendar-date{
    &:hover{
        background: #ddd !important;
    }

    &:active{
        background: #d6a76f !important;
        color: #fff;
    }
}

.pwa{
    .ant-notification{
        //background: #d6a76f;
        //max-width: 100%;
        //margin: 0;
        //
        //bottom: auto !important;
        //top: 75px !important;
        //
        //.ant-notification-notice{
        //    padding: 10px 0 !important;
        //    max-width: 100%;
        //    background: none;
        //    margin: 0;
        //
        //    box-shadow: none;
        //    -moz-box-shadow: none;
        //    -webkit-box-shadow: none;
        //}

        @media only screen and (max-width: 1024px) { bottom: 75px !important; }
    }
}

.ant-notification-notice{
    max-width: 90%;
    float: right;
    width: 100%;

    .ant-notification-notice-content{
        width: 100%;

        .ant-notification-notice-with-icon{
            width: 100%;
        }
    }
}

.ant-notification-notice-icon{
    position: relative !important;
    float: left;
}

.ant-notification-notice-with-icon .ant-notification-notice-message{
    margin: 0 0 4px !important;
    width: calc(100% - 50px);
    text-align: left;
    padding: 0;
}

.ant-notification-notice-with-icon .ant-notification-notice-description{
    width: calc(100% - 80px) !important;
    margin-left: 0 !important;
    text-align: left;
}


.ant-modal-mask{
    background: rgba(0,0,0, 0.95) !important;
    z-index: 9999 !important;
}

.ant-modal-wrap{
    z-index: 9999 !important;

    &.benefitsPopup{
        .ant-modal-close-x{
            svg{
                fill: #fff !important;
            }
        }

        .ant-modal-content{
            background: none;

            .ant-modal-body{
                padding: 65px 0;
                color: #fff;

                img{ margin: 25px 0; }
            }
        }
    }

    &.videoPopup{
        .ant-modal {
            max-width: 960px;
            width: 90% !important;

            border-radius: 5px;
            overflow: hidden;

            .ant-modal-content{
                width: 100%;

                .ant-modal-body{
                    position: relative;
                    padding-top: 56.25%;
                    overflow: hidden;
                    width: 100%;

                    iframe{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 0;
                    }
                }

                .ant-modal-close{
                    position: absolute;
                    outline: none;
                    right: 0;
                    top: 0;

                    .ant-modal-close-x {
                        background: rgba(0,0,0, 0.3);
                        font-size: 30px;
                        line-height: normal;
                        margin-right: 5px;

                        svg{
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }

    &.registerPopup{
        .ant-modal{
            max-width: 960px;
            width: 90% !important;

            border-radius: 5px;
            overflow: hidden;

            .ant-modal-body{
                display: flex;
                align-items: center;
                justify-content: center;

                padding: 0;
            }

            .ant-modal-close{
                outline: none;

                .ant-modal-close-x {
                    font-size: 30px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.ant-slider{
    margin-left: 0 !important;
    float: left;
    width: 90%;

    .ant-slider-rail,
    .ant-slider-step{ left: 0; }
    .ant-slider-rail{ background: #E5E5E5; }
    .ant-slider-track{ background: #D6A76F !important; }
    .ant-slider-handle{
        border-color: #D6A76F !important;
        margin-top: -8px;
        height: 20px;
        width: 20px;

        &.ant-tooltip-open,
        &:focus{
            border-color: #D6A76F;
            box-shadow: 0 0 0 5px rgba(214, 167, 111, 0.2);
            -moz-box-shadow: 0 0 0 5px rgba(214, 167, 111, 0.2);
            -webkit-box-shadow: 0 0 0 5px rgba(214, 167, 111, 0.2);
        }
    }
}


.slick-slider{
    .slick-slide{
        display: inline-block;
        position: relative;

        img{
            max-width: 100%;
        }
    }
}

.ant-input-number{
    &:hover{
        border-color: #d6a76f !important;
    }

    &:focus{
        border-color: #d6a76f !important;
        box-shadow: 0 0 0 2px rgba(214, 167, 111, 0.2) !important;
        -webkit-box-shadow: 0 0 0 2px rgba(214, 167, 111, 0.2) !important;
    }
}

.ant-input-number-focused{
    border-color: #d6a76f !important;
    box-shadow: 0 0 0 2px rgba(214, 167, 111, 0.2) !important;
    -webkit-box-shadow: 0 0 0 2px rgba(214, 167, 111, 0.2) !important;
}

.ant-alert{
    text-align: left;
    width: 100%;

    span{ text-align: left; }
}

.ant-select-dropdown{
    > div {
        width: 100%;
    }

    .ant-select-dropdown-menu-item{
        text-align: left;
    }

    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled){
        background-color: #f1f1f1;
    }
}

.ant-select{
    width: auto !important;
    margin: 0 10px !important;

    .ant-select-selection--single{
        display: inline-block;
        padding: 5px 10px;
        overflow: hidden;
        height: auto;
        width: 250px;
    }

    .ant-select-selection{
        &:hover{
            border-color: #d6a76f !important;
        }

        &:active, &:focus{
            border-color: #d6a76f !important;
            box-shadow: 0 0 0 2px rgba(214, 167, 111, 0.25) !important;
            -webkit-box-shadow: 0 0 0 2px rgba(214, 167, 111, 0.25) !important;
        }
    }

    .ant-select-selection__clear{
        top: 43% !important;
    }
}

.ant-picker{
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;

    .ant-picker-suffix{
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .ant-picker-clear{
        right: 10px;
        top: 21px;
    }
}