.inactiveMixin {
    content: '';
    position: absolute;
    display: block;
}
.beforeAnimation {
    -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
}
.afterAnimation {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13),
        0 3px 3px rgba(0, 0, 0, 0.05);
    -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
.toggleWrapper {
}
.toggleWrapper input.mobileToggle {
    opacity: 0;
    position: absolute;
}
.toggleWrapper input.mobileToggle + label {
    position: relative;
    display: inline-block;
    user-select: none;
    -moz-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    -webkit-tap-highlight-color: transparent;
    height: 23px;
    width: 45px;
    // border: 1px solid #e4e4e4;
    background: #838383;
    border-radius: 45px;
    cursor: pointer;
}
.toggleWrapper input.mobileToggle + label:before {
    content: '';
    position: absolute;
    display: block;
    -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    height: 23px;
    width: 45px;
    top: 0;
    left: 0;
    border-radius: 45px;
}
.toggleWrapper input.mobileToggle + label:after {
    content: '';
    position: absolute;
    display: block;
    -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    background: white;
    height: 15px;
    width: 15px;
    top: 4px;
    left: 5px;
    border-radius: 60px;
}
.toggleWrapper input.mobileToggle:checked + label:before {
    background: $gold;
    -moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
    -o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
    -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}
.toggleWrapper input.mobileToggle:checked + label:after {
    left: 24px;
}
