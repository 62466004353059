@font-face {
    font-family: 'Noto Sans';
    src: url('../../assets/fonts/notosans/NotoSans-Bold.eot');
    src: url('../../assets/fonts/notosans/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/notosans/NotoSans-Bold.woff2') format('woff2'),
        url('../../assets/fonts/notosans/NotoSans-Bold.woff') format('woff'),
        url('../../assets/fonts/notosans/NotoSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../../assets/fonts/notosans/NotoSans-Medium.eot');
    src: url('../../assets/fonts/notosans/NotoSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/notosans/NotoSans-Medium.woff2') format('woff2'),
        url('../../assets/fonts/notosans/NotoSans-Medium.woff') format('woff'),
        url('../../assets/fonts/notosans/NotoSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
