.header {
    // Slim header on scroll
    &.slim {
        height: 50px;

        a.logo {
            margin-top: 17px;
            width: 115px;
            height: 20px;
            top: 1px;

            @media only screen and (max-width: 860px) {
                // margin-top: 13px;
                left: 60px;
            }
        }

        a.showLogin {
            line-height: 50px;

            &:after {
                top: 15px;
            }
        }

        ul.menu {
            &.is-visible {
                min-height: calc(100% - 50px);
                padding-top: 0;
                top: 50px;
            }

            li {
                a {
                    line-height: 50px;
                    font-size: 14px;

                    &.cartIcon {
                        background-size: 65%;
                        width: 25px;
                        top: 6px;
                    }
                }

                &.has-submenu {
                    ul.submenu {
                        width: 100%;
                        top: 50px;
                    }
                }
            }
        }

        #toggleMenu {
            margin-top: -2px;
        }

        .account_details {
            ul.menuIcons {
                padding-top: 10px;

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    padding-top: 4px;
                }

                li {
                    // a.icon{ height: 35px; }

                    &.hasSubmenu {
                        ul {
                            top: 40px;
                        }
                    }
                }
            }

            a.user_points {
                margin-top: 5px;

                span {
                    margin-top: -10px;
                }

                img {
                    margin-top: 5px;
                }

                b {
                    font-size: 25px;
                }
            }

            div {
                &.user_details {
                    margin-top: 14px;

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        margin-top: 4px;
                    }

                    &:after {
                        top: 15px;
                    }

                    img.tier {
                        height: 15px;
                    }

                    b {
                        font-size: 14px;
                        margin-top: 5px;
                    }

                    span.rank {
                        top: 0;
                    }

                    span.type {
                        top: -7px;
                    }

                    // Active
                    &.active {
                        ul.user_menu {
                            display: inline-block;
                            opacity: 1;
                            top: 50px;
                        }
                    }
                }
            }
        }

        .notificationsBar {
            top: -10px;
        }
    }
}
