/* =============================================================================
    o   HEADER
============================================================================= */

.header {
    transition: all 300ms ease;
    display: table-row;
    background: #010103;
    text-align: center;
    position: fixed;
    height: 75px;
    width: 100%;
    z-index: 5;
    left: 0;
    top: 0;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#010103+0,22232e+50,010103+100 */
    background: #010103; /* Old browsers */
    background: -moz-linear-gradient(left, #010103 0%, #22232e 50%, #010103 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #010103 0%, #22232e 50%, #010103 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        #010103 0%,
        #22232e 50%,
        #010103 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#010103', endColorstr='#010103',GradientType=1 ); /* IE6-9 */

    &.header--hidden {
        display: none;
    }

    &.isPWA{
        background: #1F202B;
        display: inline-block;
        position: relative;
        z-index: 9999;
        left: auto;
        top: auto;
    }

    .showLogin {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: inline-block;
        position: relative;
        line-height: 75px;
        outline: none;
        border: none;
        color: #fff;

        transition: all 10ms ease;
        margin-right: 65px;
        float: right;
        padding: 0;

        &:after {
            background-image: url('../../../assets/images/ui/user_icon.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            transition: all 300ms ease;
            display: inline-block;
            position: absolute;
            content: '';

            right: -35px;
            top: 25px;

            height: 21px;
            width: 19px;
        }

        @media only screen and (max-width: 470px) {
            span {
                display: none;
            }
        }
    }
}

.bgOverlay{
    background: rgba(0,0,0, 0.95);
    display: inline-block;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;

    height: 100%;
    width: 100%;

    &.stepTwo       { z-index: 6; }
    &.stepThree     { z-index: 6; }
}
