.pagination {
    display: inline-block;
    text-align: center;
    margin: 25px 0;
    width: 100%;

    @media screen and (max-width: 840px) {
        text-align: center;
    }

    ul {
        @include border-radius(5px);

        display: inline-block;
        background: #fff;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            border: 1px solid #bbbbbb;
            display: inline-block;
            text-align: center;
            line-height: 32px;
            font-size: 13px;

            cursor: pointer;
            margin: 0 5px;
            height: 35px;
            width: 35px;
            //float: left;

            @include border-radius(50%);

            @media screen and (max-width: 330px){
                margin: 0 2px;

                line-height: 28px;
                font-size: 12px;
                height: 30px;
                width: 30px;
            }

            a {
                // border: 1px solid #ccc;
                text-decoration: none;
                display: inline-block;
                font-weight: bold;
                color: #bbbbbb;
                float: left;
                width: 100%;

                &:hover {
                    text-decoration: none;
                }
            }

            // Hover
            &:hover {
                background: #f4f4f4;
            }

            // Active item
            &.active,
            &.active:hover {
                background: #000;
                border-color: #000;

                a {
                    border-color: $primary_color;
                    color: #fff;
                }
            }

            // Next / Prev item
            &:first-child,
            &:last-child {
                a {
                    color: $primary_color;
                    margin-top: 2px;

                    &:hover {
                        color: #fff;
                    }
                }

                &:hover {
                    border-color: $gold;
                    background: $gold;
                }
            }

            &.break-me{
                border-color:  transparent;
                background: none;
                color: #000;
                width: auto;

                a{
                    color: #000;
                }
            }

            &.disabled{ opacity: 0.3; }

            &.previous,
            &.next{
                border-color: transparent;
                background: none;
                color: #d6a76f;

                a{
                    &:before{
                        font-family: FontAwesome;
                        font-weight: normal;
                        font-style: normal;
                    }

                    position: relative;
                    color: #d6a76f;
                }
            }

            &.previous{
                a:before{
                    content: "\f053";
                }
            }

            &.next{
                a:before{
                    content: "\f054";
                }
            }
        }
    }
}

.isPWA{
    .pagination{
        ul{
            li{
                border-color: #E5E5E5;
                background: #E5E5E5;
                color: #000;

                a{
                    color: #000;
                }

                &.active{
                    border-color: #d6a76f;
                    background: #d6a76f;
                    color: #fff;

                    a{
                        color: #fff;
                    }
                }

                &.break-me{
                    border-color:  transparent;
                    background: none;
                    color: #000;
                    width: auto;

                    a{
                        color: #000;
                    }
                }

                &.previous,
                &.next{
                    border-color: transparent;
                    background: none;
                    color: #d6a76f;

                    a{
                        &:before{
                            font-family: FontAwesome;
                            font-weight: normal;
                            font-style: normal;
                        }

                        position: relative;
                        color: #d6a76f;
                    }
                }

                &.previous{
                    a:before{
                        content: "\f053";
                    }
                }

                &.next{
                    a:before{
                        content: "\f054";
                    }
                }
            }
        }
    }
}
