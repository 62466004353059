.tooltip, .bootstrap-tooltip {
    text-transform: uppercase;
    margin: 25px 0 50px;
    text-align: center;
    position: relative;
    font-weight: bold;
    cursor: pointer;
    color: #a78d81;
    width: 100%;

    &:before {
        @include font-awesome();
        margin-right: 10px;
        content: '\f059';
    }

    &.is-active {
        .details {
            display: inline-block;
        }
    }

    @media only screen and (min-width: 870px) {
        &:hover {
            .details {
                display: inline-block;
            }
        }
    }

    .details {
        @include border-radius(3px);
        @include animate(bounceIn, 500ms);

        border: 1px solid #c2c2c2;
        position: absolute;
        background: #fff;
        padding: 15px;
        width: 430px;

        left: calc((100% - 430px) / 2);
        bottom: 35px;

        display: none;

        i.fa {
            @media only screen and (min-width: 460px) {
                display: none;
            }

            float: right;
        }

        img {
            float: left;
        }

        p {
            text-transform: none;
            padding: 40px 0 0;
            margin: 0;

            width: calc(100% - 250px);

            font-weight: normal;
            font-size: 16px;
        }

        &:after {
            border-bottom: 1px solid #c2c2c2;
            border-left: 1px solid #c2c2c2;
            background: #fff;

            display: inline-block;
            position: absolute;
            height: 15px;
            width: 15px;
            content: '';

            bottom: -8px;
            left: calc((100% - 15px) / 2);

            @include rotate(-45deg);
        }

        @media only screen and (max-width: 460px) {
            left: calc(75% - 200px);
            width: 250px;

            p {
                line-height: normal;
                padding-left: 15px;
                padding-top: 15px;
                text-align: left;
                width: 100%;
            }
        }
    }
}

.confirmIspot {
    .tooltip {
        margin: 10px 0 0;

        .details {
            left: calc((100% - 320px) / 2);
            width: 320px;

            i.fa {
                position: absolute;
                right: 5px;
                top: 5px;
            }

            p {
                font-size: 14px;
                text-align: left;
                width: 100%;
                padding: 0;
            }
        }
    }
}
