.educatedAwarenessComponent {
  display: flex;
  width: 100%;
  max-width: 1100px;
  margin: 35px auto;
  @media only screen and (max-width: 900px) {
    flex-flow: column;
  }
  .promoSection {
    display: flex;
    width: calc(50% - 5px);
    margin-right: 5px;
    @media only screen and (max-width: 900px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    .half {
      position: relative;
      background: #FDC843;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 60px 20px;
      width: 50%;
      &:nth-of-type(2) {
        background: #2AD1D2;
      }
      @media only screen and (max-width: 900px) {
        padding: 120px 20px;
      }
      h3 {
        font-weight: 700;
        font-size: 32px;
        color: #FFFDFB;
        z-index: 2;
        line-height: 1;
        @media only screen and (max-width: 900px) {
          font-size: 24px;
          padding: 0;
          max-width: 200px;
        }
      }
      img {
        position: absolute;
        top: 50%;  /* position the top  edge of the element at the middle of the parent */
        left: 50%; /* position the left edge of the element at the middle of the parent */
        transform: translate(-50%, -50%) scale(0.9);

        @media only screen and (max-width: 900px) {
          transform: translate(-50%, -50%) scale(0.8);
        }
        @media only screen and (max-width: 600px) {
          transform: translate(-50%, -50%) scale(0.7);
        }
        @media only screen and (max-width: 365px) {
          transform: translate(-50%, -50%) scale(0.6);
        }
      }
    }
  }
  .detailsAwareness {
    width: calc(50% - 5px);
    margin-left: 5px;
    background: #2AD1D2;
    padding: 40px 30px;
    display: flex;
    flex-flow: column;
    text-align: left;
    justify-content: space-around;
    @media only screen and (max-width: 900px) {
      width: 100%;
      margin: 0;
    }
    h3 {
      margin: 5px 0;
      color: #FFFDFB;
      font-weight: bold;
      font-size: 32px;
      text-align: left;
      padding: 0;
      &:nth-of-type(2) {
        color: #34303D;
      }
    }
    p {
      font-weight: 500;
      color: #34303D;
      font-size: 16px;
      margin: 10px auto !important;

    }
    .infoDisclaimer {
      text-align: left;
      padding: 15px;
      border: 1px solid #fff;
      width: 100%;
      p {
        color: #FFFDFB !important;
      }
    }
  }
}