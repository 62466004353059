.cookieDisclaimer{
    position: fixed;
    z-index: 5;

    .container{
        max-width: 780px;

        text-align: left;
        position: fixed;
        padding: 15px;
        z-index: 2;

        right: 25px;
        bottom: 25px;

        background: rgba(0, 0, 0, 0.7);
        border: 1px solid #fff;
        color: #fff;
        width: 80%;
        left: calc((100% - 780px)/2);

        @media only screen and (max-width: 980px) {
            width: 90%;
            left: 5%;
        }
    }

    .closeDisclaimer{
        display: inline-block;
        position: absolute;
        cursor: pointer;
        height: 35px;
        width: 35px;

        right: 0;
        top: 0;

        &:before{
            display: inline-block;
            background: #d6a76f;
            width: 15px;
            height: 1px;
            content: '';

            //@include rotate(-45deg);
            position: relative;
            left: 7px;
            top: 2px;
        }

        &:after{
            display: inline-block;
            background: #d6a76f;
            width: 15px;
            height: 1px;
            content: '';

            position: relative;
            left: -8px;
            top: 2px;
        }

        &:hover{
            background: darken(#d6a76f, 10%);

            &:before,
            &:after{
                background: #fff;
            }
        }
    }


    @media only screen and (max-width: 460px) {
        padding-bottom: 15px;
        width: calc(100% - 50px);
        left: 25px;

        .container{
            text-align: center;
            bottom: 10px;
            border: none;
        }
    }

    p{
        display: inline-block;
        font-size: 11px !important;
        float: left;
        margin: 0 0 10px;

        width: calc(100% - 275px);
        padding-right: 25px;

        a{
            border-bottom: 1px solid #d6a76f;
            color: #fff;
        }

        @media only screen and (max-width: 820px) {
            padding-right: 0;
            text-align: left;
            width: 100%;
        }

        @media only screen and (max-width: 360px) {
            font-size: 14px;
        }
    }

    a.cookieHintSettings{
        display: inline-block;
        text-align: center;
        font-size: 12px;
        float: left;
        width: auto;

        margin-top: 20px;
        margin-left: 15px;

        color: #fff;
        border-bottom: 1px solid #d6a76f;
        font-weight: 300;

        &:hover{
            color: #fff;
        }

        @media only screen and (max-width: 820px) {
            margin: 7px 15px 0 0;
        }

        @media only screen and (max-width: 670px) {
            margin: 8px 0 0;
            float: right;
            width: auto;
            padding: 0;
        }
    }

    a.saveCookies{
        display: inline-block;
        margin: 12px 0 0 5px;

        background: #05b974 !important;
        line-height: normal !important;
        padding: 8px 35px !important;
        height: auto !important;
        color: #fff;
        float: right;
        width: auto;

        &:hover{ text-decoration: none; }
        &:after{ display: none; }

        @media only screen and (max-width: 820px) {
            margin-top: 0;
            float: none;
        }

        @media only screen and (max-width: 670px) {
            padding: 15px !important;
            margin: 15px 0 0;
            height: auto;
            width: 100%;
        }

        @media only screen and (max-width: 670px) {
            width: calc(100% - 150px) !important;
            padding: 10px 0px !important;
            text-align: center;
            margin-top: 0;
            float: left;
        }
    }
}
