.footer {
    font-family: 'Noto Sans';
    font-weight: 500;

    display: inline-block;
    position: relative;
    background: #fff;
    width: 100%;

    // Logo.js
    a.logo {
        background-image: url('../../assets/images/ui/logo/logo_footer.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: inline-block;
        text-indent: -9999px;
        width: 138px;
        height: 36px;
        float: left;

        margin-top: 5px;

        @media only screen and (max-width: 1024px) {
            width: 90px;
            height: 27px;
        }
    }

    // Hints toggle
    .toggleWrapper {
        display: inline-block;
        margin-top: 25px;
        float: left;
        clear: both;

        &.active {
            margin-bottom: 25px;
            z-index: 10;
        }

        p {
            color: #838383;
            width: auto;
            float: left;
            margin: 0;

            margin-right: 15px;
        }

        input.mobileToggle + label {
            margin-top: 2px;
        }

        @media only screen and (max-width: 1300px) {
            // margin-top: 0;
            // float: right;
            // clear: none;

            input.mobileToggle + label {
                margin-top: 5px;
                margin-left: 0;
            }
        }

        @media only screen and (max-width: 810px) {
            margin-top: 15px;
            float: none;
            clear: both;

            @media only screen and (min-width: 400px) {
                > p {
                    margin-left: 0 !important;
                }
            }
        }
    }

    // Copyright
    .details {
        margin-left: 25px;
        margin-top: 25px;
        text-align: left;
        float: left;

        @media only screen and (max-width: 1024px) {
            width: calc(40% - 25px);
        }

        p {
            font-family: 'Source Sans Pro';
            color: #999;
            width: auto;
            float: left;
            clear: both;

            letter-spacing: 0.1px;
            line-height: 20px;
            font-size: 16px;

            @media only screen and (max-width: 1024px) {
                letter-spacing: normal;
                line-height: 15px;
                font-size: 12px;
                width: 100%;
            }
        }
    }

    // Menu
    ul.columns {
        display: inline-block;
        text-align: left;
        list-style: none;
        font-size: 16px;
        float: right;
        padding: 0;

        width: auto;
        margin: 25px 0;

        > li {
            display: inline-block;
            width: 225px;
            float: left;

            &:not(.legal) {
                width: 150px;
            }
            &.always-active {
                width: 150px;
            }

            ul {
                display: inline-block;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 5px;
                    font-size: 14px;
                    width: 100%;

                    &:first-child {
                        font-weight: bold;
                        margin-bottom: 8px;
                    }

                    a {
                        font-family: 'Source Sans Pro';
                        font-size: 16px;
                        opacity: 0.5;
                        color: #000;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &.social_links {
                    li {
                        margin-right: 15px;
                        text-align: center;
                        width: 100%;

                        &:first-child {
                            font-weight: bold;
                            width: 100%;
                        }

                        a {
                            text-decoration: none;
                            font-size: 25px;
                            color: #33393f;

                            @media only screen and (max-width: 570px) {
                                font-size: 25px;
                            }

                            &:hover {
                                text-decoration: none;
                                color: $gold;
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 1024px) {
            width: 50%;

            li {
                margin-bottom: 0px !important;
                width: 100% !important;
                margin-right: 15px;
                float: right;

                ul {
                    text-align: right;
                    width: 100%;

                    &.social_links {
                        li {
                            text-align: right;
                            width: auto !important;

                            &:first-child {
                                width: 100% !important;
                            }

                            &:nth-child(3) {
                                margin-right: 15px !important;
                            }
                        }
                    }

                    li {
                        font-size: 13px;
                        cursor: pointer;

                        &:not(:first-child) {
                            margin-right: 45px;
                            display: none;
                        }

                        &:first-child {
                            &:after {
                                @include font-awesome();
                                margin-left: 15px;
                                content: '\f078';
                            }
                        }

                        a {
                            font-size: 14px;
                            line-height: 25px;
                        }
                    }
                }

                &.is-active {
                    margin-bottom: 25px;

                    li {
                        display: inline-block !important;

                        &:first-child {
                            margin-bottom: 10px !important;

                            &:after {
                                content: '\f077';
                            }
                        }
                    }
                }
            }
        }
    }

    &.showCoachmarks {
        .details {
            border-bottom: none;
            position: relative;
            z-index: 6;

            a.logo {
                display: none;
            }

            > p {
                display: none;
            }
        }

        ul.columns {
            display: none;
        }
    }
}

.xmas-footer {
    display: none;
}

/* =============================================================================
    o   RESPONSIVE
============================================================================= */

@media only screen and (max-width: 710px) {
}
