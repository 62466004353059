.ageGate{
    position: fixed;
    background: #000;
    display: table;
    height: 100vh;
    width: 100%;
    color: #fff;
    z-index: 2;
    right: 0;
    left: 0;
    bottom: -75px;

    @media only screen and (max-width: 768px) {
        top: 50px !important;
        overflow-y:scroll;
        overflow-x:scroll;
        display: block;
    }

    .centerAlign{
        vertical-align: middle;
        display: table-cell;
        padding: 25px 15px;
    }

    .firstStep{
        display: inline-block;
        max-width: 720px;
        width: 90%;
    }

    .secondStep{
        display: inline-block;
        max-width: 720px;
        width: 90%;
    }

    a.logo{
        background-image: url(iqos-logo-white.svg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100% auto;


        margin: 0 auto 35px;
        display: block;
        height: 36px;
        width: 138px;

        @media only screen and (max-width: 420px) { margin-top: 25px; }
    }

    h2{
        font-family: 'Noto Sans', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 25px;
        text-transform: uppercase;
        margin-bottom: 2vh;
        line-height: 32px;
        color: #fff;

        @media only screen and (max-width: 640px) {
            line-height: 22px;
            font-size: 18px;
        }

        @media only screen and (max-width: 420px) { margin-bottom: 25px; }
    }

    p{
        font-family: 'Noto Sans', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 2px;

        small{
            display: inline-block;
            letter-spacing: normal;
            line-height: normal;
            font-size: 11px;

            color: rgba(255,255,255, 0.5);
        }
    }

    a{
        color: #d6a76f;

        &:hover{ text-decoration: underline; color: #d6a76f; }
    }

    .firstStep,
    .secondStep{
        a.primaryButton{
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            text-align: center;
            line-height: 40px;
            font-weight: 300;
            padding: 0 15px;
            height: 42px;
            color: #fff;

            background: transparent;
            border: 1px solid #fff;
            min-width: 150px;
            margin: 0 10px;

            text-decoration: none;

            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
            supported by Chrome and Opera */

            &:after {
                transition: all 300ms ease;

                text-decoration: inherit;
                font-family: FontAwesome;
                font-weight: normal;
                font-style: normal;
                content: '\f178';

                position: relative;
                margin-left: 10px;
                font-size: 16px;
                color: #d6a76f;
                top: 1px;
            }

            &:hover {
                background: #d6a76f;
                border-color: #d6a76f;
                text-decoration: none;
                transition: all 300ms ease;

                -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.8) 30%, #000 50%, rgba(0, 0, 0, 0.8) 70%);
                animation: shine 2s infinite;
                -webkit-mask-size: 200%;

                &:after {
                    color: #fff;
                }
            }

            @-webkit-keyframes shine {
                from {
                    -webkit-mask-position: 150%;

                    &:after {
                        margin-left: 10px;
                    }
                }

                to {
                    -webkit-mask-position: -50%;

                    &:after {
                        margin-left: 15px;
                    }
                }
            }

            @media only screen and (max-width: 420px) {
                line-height: normal;
                text-align: center;
                padding: 15px;
                height: auto;
                width: 100%;

                margin-bottom: 15px;
                margin-right: 0;
                margin-left: 0;
            }

            @media only screen and (max-width: 320px) {
                margin-bottom: 15px;
                margin-right: 0;
                margin-left: 0;
                width: 100%;

                &.approve{ margin-bottom: 0; }
            }
        }

        a.primaryButtonPWA{
            @extend a.primaryButton;

            border-radius: 25px;
            -moz-border-radius: 25px;
            -webkit-border-radius: 25px;
        }

        > p:first-of-type{
            margin-top: 50px;

            @media only screen and (max-width: 420px) {
                margin-top: 25px;
            }
        }
    }
}
