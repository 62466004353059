.cookie_disclaimer {
    position: fixed;
    z-index: 5;
    display: inline-block;

    &.isPWA{
        .container{
            bottom: 100px;
        }
    }

    .container {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;

        text-align: left;
        max-width: 780px;
        position: fixed;
        padding: 15px;
        z-index: 2;
        right: 25px;
        bottom: 25px;
        background: rgba(17,17,17,0.8);
        border: 1px solid rgba(255,255,255,0.5);
        color: #fff;
        width: 100%;
        left: 0;
        margin: 0 auto;

        @media only screen and (max-width: 670px) {
            width: 90%;
            left: 5%;
        }
    }

    p {
        font-family: Source Sans Pro, sans-serif;
        display: inline-block;
        font-size: 11px;
        margin-top: 0;
        float: left;
        width: calc(100% - 275px);
        color: #fff;

        @media only screen and (max-width: 820px) {
            padding-right: 0;
            text-align: left;
            font-size: 10px;
            width: 100%;
        }
    }

    @media only screen and (max-width: 460px) {
        padding-bottom: 15px;
        width: calc(100% - 50px);
        left: 25px;

        .container {
            text-align: center;
        }
    }

}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 780px;

    text-align: left;
    position: fixed;
    padding: 15px;
    z-index: 2;
    right: 25px;
    bottom: 25px;
    background: rgba(0, 0, 0, .7);
    border: 1px solid #fff;
    color: #fff;
    width: 100%;
    left: 0;

    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;

    @media only screen and (max-width: 920px) {
        display: inline-block;
    }
}

.buton_conditii {
    border-bottom: 1px solid #d6a76f;
    color: #fff;

    &:hover {
        color: #ffffff;
    }
}

.cookie_hint_settings {
    font-family: Source Sans Pro, sans-serif;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    float: left;
    width: auto;
    color: #fff;
    border-bottom: 1px solid #d6a76f;
    font-weight: 300;

    &:hover {
        color: #ffffff;
    }

    @media only screen and (max-width: 920px) {
        padding: 10px 0 0;
        float: none;
        width: auto;
        margin: 0;
    }
}

.accept_terms {
    font-family: Source Sans Pro, sans-serif;
    line-height: normal !important;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;

    background: none !important;
    padding: 0 10px !important;
    margin: 0 0 0 20px;

    height: auto !important;
    font-size: 12px;
    float: right;
    width: auto;

    @media only screen and (max-width: 920px) {
        margin: 5px 0 0;
        float: right;
        width: auto;
    }
}

.linksContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 920px) {
        justify-content: space-evenly;
        flex-direction: row;
        width: 100%;
    }
}

.closePopup {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    float: right;

    height: auto !important;
    width: 20px !important;
    margin: 0 !important;
    right: 0 !important;
    top: -25px !important;
}
