.header {
    #toggleMenu {
        transition: all 300ms ease;
        display: none;

        margin-top: 10px;
        cursor: pointer;
        float: left;
        color: #fff;
        font-size: 21px;

        transform: scale(0.8);

        span {
            float: left;
            margin-top: 2px;
            margin-right: 10px;
            text-transform: uppercase;
        }

        .hamburger-inner {
            background-color: #fff;
            height: 2px;
            width: 30px;

            &:before,
            &:after {
                background-color: #fff;
                height: 2px;
                width: 30px;
            }
        }

        &.is-active {
            .hamburger-inner {
                background-color: #000;
            }
        }

        @media only screen and (max-width: 1210px) {
            display: inline-block;
        }

        @media only screen and (max-width: 460px) {
            margin-right: 25px;
        }
    }
}
