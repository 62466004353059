/* =============================================================================
    o   CONTENT
============================================================================= */

.content {
    display: inline-block;
    text-align: center !important;
    background: #fff;
    overflow: hidden;

    padding-top: 75px;
    width: 100%;

    &.isPWA {
        //background: #f4f4f4;
        padding-bottom: 100px;
        padding-top: 0;
    }

    &.noMarginBottom{
        margin-bottom: 0;
    }

    .container {
        overflow: hidden;
        width: 90%;

        &:first-of-type {
            margin-top: 100px;

            @media only screen and (min-height: 470px) {
                margin-top: 80px;
            }
        }

        &.small {
            max-width: 640px;

            h1 {
                text-align: center;
                margin-bottom: 0;
            }

            p {
                text-align: center;
                font-size: 24px;
                color: #838383;
            }
        }

        &.wide {
            margin-top: 0 !important;
            max-width: 100%;
            width: 100%;

            h1 {
                width: calc(100% - 50px);
                margin: 10px 25px;

                @media only screen and (max-width: 640px) {
                    font-size: 25px !important;
                    margin: 10px 5% 0;
                    width: 90%;
                }

                @media only screen and (max-width: 370px) {
                    margin-top: 45px;
                }
            }
        }

        &.notificationsContainer {
            width: calc(100% - 50px);
            overflow: initial;
            max-width: 100%;
        }

        h1 {
            font-size: 36px;
            font-weight: 300;

            b {
                font-weight: normal;
            }
        }
    }

    h2 {
        line-height: normal;
        margin-bottom: 0;
        font-weight: 300;
        font-size: 36px;
        color: #383838;
    }

    p {
        font-weight: 300;
        font-size: 18px;
        color: #838383;
    }

    .grey_bar {
        display: inline-block;
        background: #f1f1f1;
        margin-top: 25px;
        padding: 15px 0;
        width: 100%;

        .container {
            max-width: 1240px;
            padding: 0 25px;
            margin: 0;

            p {
                font-size: 16px;
                color: #838383;
                padding: 0;
                margin: 0;

                width: auto;
                float: left;

                @media only screen and (max-width: 610px) {
                    text-align: center;
                    width: 100%;
                }
            }

            a {
                font-size: 16px;
                float: right;

                @extend a.primary_button;
                line-height: normal;
                font-weight: 500;
                background: none;
                color: $darkGold;
                height: auto;
                padding: 0;
                margin: 0;

                margin-top: 3px;

                &:hover {
                    background: none;
                    color: #000;

                    &:after {
                        color: #000;
                    }
                }

                @media only screen and (max-width: 710px) {
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }

    .greySection {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d6d6d6+0,f2f2f2+50,d6d6d6+100 */
        background: #d6d6d6; /* Old browsers */
        background: -moz-linear-gradient(left, #d6d6d6 0%, #f2f2f2 50%, #d6d6d6 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #d6d6d6 0%, #f2f2f2 50%, #d6d6d6 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to right,
            #d6d6d6 0%,
            #f2f2f2 50%,
            #d6d6d6 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d6d6d6', endColorstr='#d6d6d6',GradientType=1 ); /* IE6-9 */

        display: inline-block;
        padding: 50px 0;
        margin: 35px 0;
        outline: none;
        width: 100%;

        .container {
            margin: 0;
        }
    }

    &.internal {
        .container {
            > h1 {
                text-transform: uppercase;
                letter-spacing: 3px;
                text-align: center;
                font-weight: bold;
                color: $gold;

                line-height: normal !important;
                font-size: 24px !important;
            }

            > h2 {
                text-align: center;
                font-size: 24px;
                color: #0a0a0a;

                b {
                    font-weight: 500;
                }
            }

            > p {
                text-align: center;

                @media only screen and (max-width: 770px) {
                    br {
                        display: none;
                    }
                }
            }

            img {
                &.full-width {
                    width: 100%;
                }

                &.align-left {
                    float: left;
                    margin-right: 15px;
                    margin-bottom: 10px;
                }

                &.align-right {
                    float: right;
                    margin-left: 15px;
                    margin-bottom: 10px;
                }
            }

            p {
                margin: 10px 0;
                color: #838383;

                &.author {
                    color: #b7b7b7;
                }
            }

            .heading {
                width: 100%;

                p {
                    max-width: 720px;
                    font-size: 19px;
                    color: #000;
                    margin: 0;
                }
            }
        }

        &.terms {
            text-align: left;

            p {
                text-align: left;
            }

            ul {
                li {
                    font-weight: 300;
                    font-size: 18px;
                    color: #838383;
                }
            }
        }
    }

    &.products {
        > .container {
            > h3 {
                margin: 50px 0 25px;
                text-align: center;
                font-weight: 300;
                font-size: 36px;
                color: #383838;
            }
        }

        .mainContent {
            margin-top: 75px;
        }
    }
}

/* =============================================================================
    o   RESPONSIVE
============================================================================= */

@media only screen and (max-width: 710px) {
}
