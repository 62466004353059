@font-face {
    font-family: 'RRP Font v5';
    src: url('../../assets/fonts/rrp/RRP-Regular.eot');
    src: url('../../assets/fonts/rrp/RRP-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/rrp/RRP-Regular.woff2') format('woff2'),
    url('../../assets/fonts/rrp/RRP-Regular.woff') format('woff'),
    url('../../assets/fonts/rrp/RRP-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
