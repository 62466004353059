@font-face {
    font-family: 'Source Sans Pro';
    src: url('../../assets/fonts/sourcesans/SourceSansPro-Regular.eot');
    src: url('../../assets/fonts/sourcesans/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/sourcesans/SourceSansPro-Regular.woff2') format('woff2'),
        url('../../assets/fonts/sourcesans/SourceSansPro-Regular.woff') format('woff'),
        url('../../assets/fonts/sourcesans/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../../assets/fonts/sourcesans/SourceSansPro-Light.eot');
    src: url('../../assets/fonts/sourcesans/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/sourcesans/SourceSansPro-Light.woff2') format('woff2'),
        url('../../assets/fonts/sourcesans/SourceSansPro-Light.woff') format('woff'),
        url('../../assets/fonts/sourcesans/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../../assets/fonts/sourcesans/SourceSansPro-Bold.eot');
    src: url('../../assets/fonts/sourcesans/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/sourcesans/SourceSansPro-Bold.woff2') format('woff2'),
        url('../../assets/fonts/sourcesans/SourceSansPro-Bold.woff') format('woff'),
        url('../../assets/fonts/sourcesans/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
