form {
    display: inline-block;
    text-align: left;
    width: 100%;

    label:not(.control) {
        display: inline-block;
        margin-bottom: 35px;
        position: relative;
        width: 100%;
        float: left;

        @media only screen and (max-width: 720px) {
            width: 100% !important;
        }

        .form-group {
            margin-bottom: 0;
            width: 100%;
            float: left;
        }

        &.fullwidth {
            width: 100%;
        }

        // Label text
        p {
            transition: all 0.5s ease;
            text-transform: uppercase;
            display: inline-block;
            position: relative;
            overflow: hidden;
            float: left;
            width: 100%;
            color: #000;

            margin: 0 !important;
            font-weight: bold;
            font-size: 14px;
            height: auto;
            opacity: 1;

            b {
                color: $primary_color;
            }
        }

        // Input
        input,
        select {
            transition: all 0.5s ease;
            display: inline-block;
            padding: 12px 20px 12px 0;
            cursor: pointer;
            font-size: 16px;
            outline: none;
            float: left;
            width: 100%;

            -ms-appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;

            border: none;
            background: none;

            border-bottom: 2px solid #333333;
            font-family: $sourcesanspro;
            // letter-spacing: 2px;
            font-weight: 500;
            font-size: 16px;
            color: #000;

            &[name='email'] {
                // text-transform: lowercase;
            }
        }

        textarea {
            border: 1px solid #ccc;
            outline-color: $primary_color;
            font-family: $sourcesanspro;
            transition: all 0.5s ease;
            display: inline-block;
            background: #fff;
            font-size: 16px;
            outline: none;
            padding: 15px;
            width: 100%;

            -ms-appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
        }

        input,
        select,
        textarea {
            &:focus {
                box-shadow: none;
                outline: none;

                border-color: $gold;
            }

            &:focus,
            &:not(:valid) {
                & ~ p {
                    margin-top: -65px !important;
                    font-size: 12px;
                    opacity: 1;
                }
            }
        }

        // Error message
        span.error {
            display: none;
        }

        // Email custom icon
        &.email {
            &:before {
                content: 'email';
                font-family: 'Material Icons';
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;

                text-align: center;
                line-height: 45px;
                font-size: 20px;
                color: #fff;
            }
        }

        // Password custom icon
        &.password {
            &:before {
                content: 'lock';
                font-family: 'Material Icons';
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;

                text-align: center;
                line-height: 45px;
                font-size: 20px;
                color: #fff;
            }
        }


        // Select
        &.select {
            display: inline-block;
            margin-bottom: 35px;
            position: relative;
            //width: 100%;
            float: left;

            &:after {
                content: '\f078';
                font-family: FontAwesome;
                text-decoration: inherit;
                font-weight: normal;
                font-style: normal;
                background: transparent;

                display: inline-block;
                pointer-events: none;
                position: absolute;
                text-align: center;
                line-height: 45px;
                height: 41px;
                width: 45px;
                color: #000;
                right: 1px;
                top: 19px;
            }
        }


        // No errors
        &.no-errors {
            &:before {
                background: #00bf2d;
            }

            input,
            select {
                outline-color: #00bf2d;
                border-color: #00bf2d;
            }
        }

        // Show error
        &.has-error {
            &:before {
                background: #ff144b;
            }

            input,
            select,
            textarea {
                outline-color: #ff144b;
                border-color: #ff144b;
                outline: none;
            }

            span.error {
                display: inline-block;
                padding-left: 25px;
                position: relative;
                line-height: 18px;
                text-align: left;
                margin-top: 10px;
                font-size: 14px;
                color: #ff144b;
                width: 100%;

                &:before {
                    font-family: 'Material Icons';
                    text-decoration: inherit;
                    font-weight: normal;
                    font-style: normal;
                    content: 'info';

                    display: inline-block;
                    position: absolute;
                    text-align: center;
                    font-size: 16px;

                    left: 0px;
                    top: -1px;

                    // IE FIX
                    text-rendering: optimizeLegibility;
                    font-feature-settings: 'liga';
                    ms-font-feature-settings: 'liga';
                }
            }
        }

        // Show success

        span.success {
            display: inline-block;
            padding-left: 25px;
            position: relative;
            line-height: 18px;
            text-align: left;
            margin-top: 10px;
            font-size: 14px;
            color: #00bf2d;

            &:before {
                font-family: 'Material Icons';
                text-decoration: inherit;
                font-weight: normal;
                font-style: normal;
                content: 'check_circle';

                display: inline-block;
                position: absolute;
                text-align: center;
                font-size: 16px;

                left: 0px;
                top: -1px;
            }
        }
    }

    // Custom upload input
    .custom-upload {
        display: inline-block;
        position: relative;
        float: left;
        width: 49%;

        @media only screen and (max-width: 720px) {
            width: 100%;
        }

        &.is_hidden {
            display: none;
        }

        label {
            width: 100% !important;
        }

        .custom-upload__input {
            position: absolute;
            overflow: hidden;
            left: -10000px;
            height: 0;
        }

        .custom-upload__button {
            border: 2px solid $primary_color;
            background-color: transparent;
            color: $primary_color;
            text-align: center;
            line-height: 41px;
            margin-top: 24px;
            cursor: pointer;

            @include border-radius(5px);

            i {
                position: absolute;
                right: 15px;
                top: 8px;
            }

            &:hover {
                background: $primary_color;
                color: #fff;
            }
        }

        span.error {
            display: none;
            padding-left: 25px;
            position: relative;
            line-height: 18px;
            text-align: left;
            margin-top: 10px;
            font-size: 14px;
            color: #ff144b;

            &:before {
                font-family: 'Material Icons';
                text-decoration: inherit;
                font-weight: normal;
                font-style: normal;
                content: 'info';

                display: inline-block;
                position: absolute;
                text-align: center;
                font-size: 16px;

                left: 0px;
                top: -1px;
            }
        }

        &.has-error {
            .custom-upload__button {
                border-color: #ff144b;
            }

            span.error {
                display: inline-block;
            }
        }
    }

    // Submit button
    button[type='submit'] {
        outline-color: $primary_color;
        background: $primary_color;
        transition: all 0.5s ease;
        display: inline-block;
        line-height: 45px;
        cursor: pointer;
        border: none;
        width: 100%;
        margin: 0;

        text-align: center;
        margin-top: 10px;
        font-size: 16px;
        color: #fff;

        &:hover {
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.2rem rgba($primary_color, 0.25);
            -moz-box-shadow: 0 0 0 0.2rem rgba($primary_color, 0.25);
            -webkit-box-shadow: 0 0 0 0.2rem rgba($primary_color, 0.25);
        }
    }
}

.isPWA{
    form{
        label:not(.control){
            input{ border-bottom: 2px solid #000; }
            textarea{ border: none; border-bottom: 2px solid #000; }
        }

        label{
            select{
                border-bottom: 2px solid #000;
            }
        }

        button[type="submit"]{
            padding: 15px 10px;

            border-radius: 25px;
            -moz-border-radius: 25px;
            -webkit-border-radius: 25px;
        }
    }
}

// Checkboxes and radios
.control {
    display: inline-block;
    margin-bottom: 15px;
    padding-left: 40px;
    position: relative;
    cursor: pointer;
    width: 100% !important;
    font-size: 14px;
    min-width: 16px;
    color: #333333;

    h3 {
        position: relative;
        margin-bottom: 5px;
        top: -2px;
    }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
}

.control__indicator {
    background: none;
    border: 2px solid #333333;
    position: absolute;
    height: 28px;
    width: 28px;
    top: 2px;
    left: 0;

    &:after {
        content: '';
        position: absolute;
        display: none;
    }
}

.control input.prevChecked ~ .control__indicator:after {
    display: block;
}

.control input.prevChecked ~ .control__indicator {
    border-color: $primary_color !important;
    border: none;
    background: $primary_color !important;
}

.control--radio .control__indicator {
    border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
    background: #f1f1f1;
}
.control input:checked ~ .control__indicator {
    border-color: $primary_color !important;
    border: none;
    background: $primary_color !important;
}

.control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}

.control input:checked ~ .control__indicator:after {
    display: block;
}

.control--checkbox .control__indicator:after {
    left: 10px;
    top: 3px;
    width: 8px;
    height: 16px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
    left: 7px;
    top: 7px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
}

.App{
    &.isPWA{
        form{
            label:not(.control){
                margin-bottom: 25px;

                input, select{
                    border-bottom: 2px solid #1F1E2F;
                }

                &.select{
                    &:after{
                        color: #d6a76f;
                    }
                }
            }
        }
        .control{
            padding-left: 40px;
        }

        .control__indicator{
            border: 2px solid #1F1E2F;
            height: 25px;
            width: 25px;
        }

        .control--checkbox .control__indicator:after{
            left: 10px;
            top: 6px;
        }
    }
}

// Disable OSx default styling
input, select, textarea{
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

select{
    option{
        color: #000;
    }
}