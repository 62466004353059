html,
body,
div,
span,
applet,
object,
iframe {
    vertical-align: baseline;
    display: inline-block;
    text-align: center;
    outline: none;
    padding: 0;
    border: 0;
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
div {
    display: inline-block;
}

html,
body {
    display: inline-block;
    background: #f4f4f4;
    height: 100%;
    width: 100%;
}

*,
*::before,
*::after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-overflow-scrolling: touch;
}

body {
    -webkit-overflow-scrolling: touch;
    -webkit-touch-callout: none;

    background-position: center center;
    background-color: $bg_color;
    background-size: cover;

    font-family: $sourcesanspro;
    color: $text_color;
    line-height: 1.5;
    font-size: 14px;

    min-height: 100%;
    //display: table;
    height: 100%;
    width: 100%;

    &.no-scroll {
        overflow: hidden;
    }

    &::-moz-selection {
        background: $gold;
        color: #fff;
    }
    &::selection {
        background: $gold;
        color: #fff;
    }

    img {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
            supported by Chrome and Opera */
    }

    // Fix for Material Icons on IE 11
    text-rendering: optimizeLegibility;
    font-kerning: normal;
    font-variant-ligatures: common-ligatures contextual;
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';

    #app {
        overflow: hidden;
        display: table;
        height: 100%;
        width: 100%;
    }

    &.noTable {
        display: inline-block;
        height: auto;

        #app {
            display: inline-block;
        }

        .content {
            display: inline-block !important;
            height: auto;
            width: 100%;
        }
    }
}

a {
    text-decoration: none;
    color: $link_color;
    outline: none;

    &:hover {
        text-decoration: underline;
    }
}

.container {
    position: relative;
    max-width: 1240px;
    text-align: left;
    margin: 0 auto;
    padding: 0;
    width: 95%;
}

.center-align {
    display: table-cell;
    vertical-align: middle;
}

.center {
    display: inline-block;
    text-align: center;
    width: 100%;
}

.clearfix {
    display: inline-block;
    height: 1px;
    width: 100%;
}

.TextAlign-center {
    text-align: center;
    margin: 15px 0;
    width: 100%;
}

.flr {
    float: right;
}

.fll {
    float: left;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    width: 90%;
    height: 0;

    iframe {
        position: absolute;
        height: 100%;
        width: 100%;
        top: -75px;
        left: 0;

        @media only screen and (max-width: 960px) {
            top: -50px;
        }
        @media only screen and (max-width: 640px) {
            top: -25px;
        }
    }
}

.isLoading {
    pointer-events: none;
    position: relative;
    opacity: 0.5;

    &:after {
        background-image: url('../../assets/images/ui/loader.gif');
        background-position: center center;
        background-repeat: no-repeat;
        background-color: #fff;
        background-size: 75%;

        display: inline-block;
        pointer-events: none;
        position: absolute;
        height: 64px;
        width: 64px;
        content: '';

        @include border-radius(50%);
        left: calc((100% - 64px) / 2);
        top: calc((100% - 64px) / 2);

        -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    }
}

input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}

select::-ms-expand {
    display: none;
}

.switch-wrapper {
    position: relative;

    > div {
        position: absolute;
    }
}

.Collapsible {
    width: 100%;
}

.PrivateTabIndicator-colorSecondary-45,
.PrivateTabIndicator-colorSecondary-74,
.PrivateTabIndicator-colorSecondary-36,
.PrivateTabIndicator-colorSecondary-112,
.PrivateTabIndicator-root-34,
.MuiTabs-flexContainer > span{
    //background-color: #d6a76f !important;
    //height: 3px !important;
}

.MuiTabs-indicator{
    background-color: #d6a76f !important;
    height: 3px !important;
}

.carousel{
    .slider-wrapper{ float: left; }
    .slide{
        background: none !important;

        a{ display: inline-block; }

        div{
            float: left;
            width: 100%;
        }

        img{
            max-height: 150px;
            max-width: 100%;
            width: auto !important;
        }
    }

    .thumbs-wrapper{ margin: 0 !important; }
    .thumbs {
        border: none;
        width: 100%;
        padding: 0;
        margin: 0;

        .thumb {
            cursor: pointer;
            margin: 0 1px;
            border: none;

            max-width: 50px;
            width: auto;

            &:focus{ border: none; }
            &:hover{
                position: relative;
                border: none;

                &:after{
                    display: inline-block;
                    position: absolute;
                    background: #000;
                    height: 1px;
                    width: 100%;
                    content: '';

                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
}

// Ratings

.dv-star-rating{
    label:not(.control){
        width: auto !important;
        margin-bottom: 0;
        font-size: 25px;
    }
}
