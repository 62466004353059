/* =============================================================================
    o   POPUP
============================================================================= */

.bg-overlay {
    display: none;
    background: rgba(0, 0, 0, 0.985);
    position: fixed;
    z-index: 4;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}

.pp-body {
    overflow: hidden;
    position: fixed;
    display: table;
    height: 100%;
    width: 100%;
    z-index: 5;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    display: none;

    &.visible {
        @include animate(300ms, pulse);
        display: table;
    }

    .pp-wrapper {
        display: table-row;
        vertical-align: middle;
        text-align: center;

        .center-align {
            display: table-cell;
            vertical-align: middle;
            position: relative;
            height: 100%;

            .pp-content {
                display: inline-block;
                position: relative;
                background: #fff;
                margin: 25px 0;
                padding: 25px;
                width: 90%;

                max-width: 820px;
                max-height: 720px;

                &.medium {
                    max-width: 640px;
                }

                &.small {
                    max-width: 320px;

                    h3 {
                        margin-bottom: 0px !important;
                        margin-top: 0;
                    }

                    label {
                        width: 100% !important;
                    }

                    button[type='submit'] {
                        max-width: 100% !important;
                        width: 100% !important;
                    }
                }

                .close-pp {
                    i {
                        transition: all 0.5s ease;
                        background: $primary_color;
                        position: absolute;
                        text-align: center;
                        line-height: 50px;
                        font-size: 25px;
                        cursor: pointer;
                        right: -15px;
                        height: 50px;
                        color: #fff;
                        width: 50px;
                        opacity: 1;

                        right: -25px;
                        top: -25px;

                        border-radius: 50%;
                        -moz-border-radius: 50%;
                        -webkit-border-radius: 50%;

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                a.primary-button {
                    transition: all 0.5s ease;
                    background: #000;
                    display: inline-block;
                    padding: 15px 35px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 16px;
                    cursor: pointer;
                    margin: 15px 0;
                    border: none;
                    color: #fff;
                    opacity: 1;

                    border-radius: 10px;
                    -moz-border-radius: 10px;
                    -webkit-border-radius: 10px;

                    box-shadow: 0 5px 10px 0px rgba(85, 85, 85, 0.12);
                    -moz-box-shadow: 0 5px 10px 0px rgba(85, 85, 85, 0.12);
                    -webkit-box-shadow: 0 5px 10px 0px rgba(85, 85, 85, 0.12);
                }

                h4 {
                    text-transform: uppercase;
                    display: inline-block;
                    margin-bottom: 10px;
                    line-height: 22px;
                    font-weight: bold;
                    font-size: 22px;
                    color: $primary_color;
                    width: 100%;
                }

                h5 {
                    text-transform: uppercase;
                    display: inline-block;
                    margin-bottom: 15px;
                    line-height: 15px;
                    font-weight: bold;
                    font-size: 14px;
                    color: #000000;
                    width: 100%;

                    b {
                        color: $primary_color;
                    }
                }

                .pp-details {
                    width: calc(100% + 25px);
                    display: inline-block;
                    padding-right: 25px;
                    max-height: 500px;
                    text-align: left;
                    overflow: auto;
                    height: auto;

                    i {
                        &.success {
                            font-size: 50px;
                            color: green;
                        }
                    }

                    h1,
                    h2 {
                        margin: 0;
                    }

                    > b {
                        display: inline-block;
                        text-align: justify;
                        margin-bottom: 15px;
                        text-indent: 50px;
                        font-size: 16px;
                        width: 100%;
                        font-weight: bold;
                        color: #000;
                    }

                    > span {
                        display: inline-block;
                        text-decoration: underline;
                        margin-bottom: 15px;
                        font-weight: bold;
                        text-indent: 50px;
                        text-align: left;
                        font-size: 16px;
                        float: left;
                    }

                    p {
                        display: inline-block;
                        font-size: 16px;
                        width: 100%;

                        a {
                            text-decoration: underline;
                            font-weight: bold;
                            color: $primary_color;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    ul {
                        display: inline-block;
                        text-align: left;
                        width: 100%;

                        li {
                            font-size: 16px;
                            width: 100%;

                            span {
                                text-decoration: none;
                                margin-bottom: 5px;
                                margin-right: 5px;
                            }
                        }
                    }
                }

                .terms {
                    display: inline-block;
                    width: calc(100% + 25px);
                    padding-right: 25px;
                    max-height: 500px;
                    overflow: auto;
                    height: auto;
                    color: #000;

                    > b {
                        display: inline-block;
                        text-align: justify;
                        margin-bottom: 15px;
                        text-indent: 50px;
                        font-weight: bold;
                        font-size: 16px;
                        width: 100%;
                        color: #000;
                    }

                    p {
                        display: inline-block;
                        text-align: justify;
                        font-size: 16px;
                        color: #000;
                        width: 100%;

                        a {
                            text-decoration: underline;
                            color: $primary_color;
                            font-weight: bold;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    ul,
                    ol {
                        display: inline-block;
                        text-align: left;
                        width: 100%;

                        li {
                            font-size: 16px;
                            width: 100%;

                            span {
                                text-decoration: none;
                                margin-bottom: 5px;
                                margin-right: 5px;
                            }
                        }
                    }

                    span {
                        display: inline-block;
                        font-weight: bold;
                        text-decoration: underline;
                        margin-bottom: 15px;
                        text-indent: 50px;
                        text-align: left;
                        font-size: 16px;
                        float: left;
                    }
                }

                button.button {
                    transition: all 0.5s ease;
                    display: inline-block;
                    background: $primary_color;
                    /* position: absolute; */
                    padding: 15px 25px;
                    font-weight: bold;
                    font-size: 14px;
                    outline: none;
                    color: #ffffff;
                    border: none;
                    margin-top: 15px;
                    width: calc(100% - 100px);
                    /* margin-left: 50px; */
                    opacity: 1;
                    left: 0;
                    bottom: 25px;
                    cursor: pointer;

                    border-radius: 50px;
                    -moz-border-radius: 50px;
                    -webkit-border-radius: 50px;

                    -webkit-box-shadow: 5px 5px 20px -8px $primary_color;
                    -moz-box-shadow: 5px 5px 20px -8px $primary_color;
                    box-shadow: 5px 5px 20px -8px $primary_color;

                    &:hover {
                        text-decoration: none;
                        box-shadow: none;
                    }

                    &[disabled] {
                        opacity: 0.5;
                        cursor: no-drop;
                    }
                }

                span.go_bottom {
                    display: inline-block;
                    color: $primary_color;
                    font-size: 20px;
                    padding: 8px 15px;
                    position: absolute;
                    cursor: pointer;
                    bottom: 25px;
                }
            }
        }
    }
}

#preview_pdf {
    .pp-content {
        max-height: 900px;

        .pp-details {
            max-height: 825px;

            img.preview_factura {
                border: 2px solid $primary_color;
                display: inline-block;
                margin: 25px 0;
                width: 100%;
                float: left;
            }

            a.print_now {
                background: $primary_color;
                text-align: center;
                line-height: 55px;
                float: left;
                color: #fff;
                width: 49%;

                @include border-radius(5px);

                &:hover {
                    background: darken($primary_color, 5%);
                    text-decoration: none;
                }
            }

            a.go_back {
                line-height: 51px;
                float: right;
                width: 49%;
                margin: 0;

                border: 2px solid $primary_color;
                @include border-radius(5px);

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

/* =============================================================================
    o   RESPONSIVE
============================================================================= */

@media only screen and (max-width: 550px) {
    .pp-body .pp-wrapper .center-align .pp-content {
        button.button {
            font-size: 12px;
            padding: 10px;
        }

        h4 {
            font-size: 20px;
            line-height: 20px;
        }
    }
}

@media only screen and (max-width: 480px) {
    .pp-body .pp-wrapper .center-align .pp-content .pp-details {
        span {
            text-indent: 0;
        }

        p,
        > b,
        ul li {
            font-size: 14px;
            text-indent: 0;
        }
    }
}

@media only screen and (max-width: 380px) {
    .pp-body .pp-wrapper .center-align .pp-content {
        margin: 25px 0;
        padding: 15px;
    }
}

/* =============================================================================
    o   RESPONSIVE | HEIGHT
============================================================================= */

@media only screen and (max-height: 960px) {
    .pp-body .pp-wrapper .center-align .pp-content .pp-details {
        max-height: 350px;
    }
}

@media only screen and (max-height: 690px) {
    .pp-body .pp-wrapper .center-align .pp-content .pp-details {
        max-height: 300px;
    }
}

@media only screen and (max-height: 520px) and (min-width: 640px) {
    .pp-body .pp-wrapper .center-align .pp-content h4 {
        font-size: 21px;
        line-height: 21px;
    }

    .pp-body .pp-wrapper .center-align .pp-content .pp-details {
        max-height: 125px;
    }
}

@media only screen and (max-height: 520px) {
    .pp-body .pp-wrapper .center-align .pp-content .pp-details {
        max-height: 250px;
    }
}

@media only screen and (max-height: 460px) {
    .pp-body .pp-wrapper .center-align .pp-content .pp-details {
        max-height: 175px;
    }
}
