.header {
    .account_details {
        transition: all 300ms ease;
        display: inline-block;
        position: absolute;
        right: 25px;
        top: 0;

        ul.menuIcons {
            transition: all 300ms ease;
            display: inline-block;
            list-style: none;
            padding: 0;
            margin: 0;

            margin-left: 15px;
            padding-top: 21px;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                padding-top: 17px;
            }

            li {
                display: inline-block;

                @media only screen and (min-width: 460px) {
                    margin-left: 5px;
                }

                div.icon, a.icon {
                    background-position: top center;
                    background-repeat: no-repeat;
                    background-size: 100% auto;

                    display: inline-block;
                    position: relative;
                    height: 45px;
                    width: 35px;
                    padding: 0;

                    fill: rgba(255, 255, 255, 0.5);
                    float: left;

                    &:hover {
                        fill: rgba(255, 255, 255, 1);
                    }

                    svg {
                        max-width: 25px;
                    }

                    span {
                        display: inline-block;
                        background: #c81111;
                        position: absolute;
                        font-size: 11px;
                        height: 17px;
                        width: 17px;
                        color: #fff;

                        right: -3px;
                        top: -6px;

                        @include border-radius(50%);
                    }

                    &.notifications {
                        height: 48px;

                        span {
                            top: -2px;
                        }

                        svg {
                            max-width: 100%;

                            path {
                                transition: all 300ms ease;
                                fill: none;
                                stroke: rgba(255, 255, 255, 0.5);
                                stroke-width: 8px;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                            }
                        }

                        &:hover {
                            svg path {
                                stroke: rgba(255, 255, 255, 1);
                            }
                        }
                    }

                    &.cart {
                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                            top: -4px;
                        }
                    }

                    // &.cart              { background-image: url(../images/ui/cart.png); }
                    // &.notifications     { background-image: url(../images/ui/bell.png); }
                }

                &.hasSubmenu {
                    position: relative;

                    ul {
                        transition: all 300ms ease;
                        display: inline-block;
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li {
                            display: inline-block;
                            border-bottom: 1px solid #e8e8e8;
                            position: relative;
                            float: left;
                            width: 100%;

                            padding: 10px;
                            margin: 0;

                            a {
                                display: inline-block;
                                width: 100%;
                                float: left;
                                color: #000;

                                &.closeNotification {
                                    transition: all 300ms ease;
                                    position: absolute;
                                    padding: 5px 7px;
                                    display: none;
                                    width: auto;
                                    z-index: 1;
                                    right: 3px;
                                    top: 5px;

                                    i {
                                        line-height: normal;
                                        font-size: 13px;
                                        color: #000;
                                        width: auto;
                                    }
                                }

                                i {
                                    @include border-radius(50%);
                                    display: inline-block;
                                    text-align: center;
                                    line-height: 35px;
                                    float: left;
                                    width: 35px;

                                    background: #f5efe8;
                                    color: #ae885c;
                                    font-size: 18px;
                                }

                                .details {
                                    display: inline-block;
                                    width: calc(100% - 40px);
                                    text-align: left;
                                    float: right;

                                    p {
                                        font-family: Source Sans Pro;
                                        display: inline-block;
                                        letter-spacing: normal;
                                        line-height: normal;
                                        font-size: 14px;
                                        margin: 0;

                                        text-transform: none;
                                        font-weight: normal;
                                        color: #000;
                                    }

                                    small {
                                        display: inline-block;
                                        color: #d6a76f;
                                    }
                                }
                            }

                            &:hover {
                                background: #f9f9f9;

                                a.closeNotification {
                                    display: inline-block;
                                }
                            }
                        }

                        a.primary_button {
                            color: $primary_color;
                            font-weight: bold;
                            background: none;

                            line-height: normal;
                            font-size: 12px;
                            padding: 10px 0;
                            height: auto;

                            &:after {
                                display: none;
                            }
                        }
                    }

                    &:hover {
                        ul {
                            display: inline-block;
                        }
                    }

                    @media only screen and (max-width: 460px) {
                        ul {
                            right: -75px;

                            &:before {
                                right: 85px;
                                left: auto;
                            }
                        }
                    }

                    @media only screen and (max-width: 360px) {
                        ul {
                            width: 250px;
                        }
                    }
                }
            }

            &.signOut {
                li {
                    .cls-1 {
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 8px;
                        stroke: #f9f9fa;
                        fill: none;
                    }
                }
            }
        }

        a.user_points {
            transition: all 300ms ease;
            float: left;
            width: auto;
            padding: 0 15px 0 0;
            margin: 25px 0 0 0;

            border-right: 1px solid #333335;
            text-transform: uppercase;
            display: inline-block;
            font-family: $planer;
            color: $gold;

            span {
                display: inline-block;
                letter-spacing: 2px;
                margin-top: -5px;
                float: right;
                clear: both;
            }

            img {
                display: inline-block;
                margin-right: 2px;
                max-width: 55px;
                float: right;
                clear: both;
            }

            b {
                transition: all 300ms ease;
                font-weight: normal;
                margin-right: 0px;
                font-size: 25px;
            }

            @media only screen and (max-width: 960px) {
                border-right: none;
                padding-right: 10px;
                // margin-top: 23px;
            }

            @media only screen and (max-width: 580px) {
                margin-top: 23px;
                padding-right: 0;

                b {
                    // font-weight: bold;
                    // font-size: 20px;
                    // margin-right: 0;
                }
            }

            @media only screen and (max-width: 500px) {
                display: none;
            }
        }

        div {
            transition: all 300ms ease;
            float: left;
            width: 100%;
            margin: 0;

            &.popup-overlay{
                opacity: 0 !important;
                display: none;
            }

            &.popup-content{
                transition: all 300ms ease !important;
                background: #fff !important;
                z-index: 99999 !important;
                padding: 0px !important;
                border: none !important;
                width: 225px !important;

                -webkit-box-shadow: 0px 0px 25px -12px rgba(0, 0, 0, 1) !important;
                -moz-box-shadow: 0px 0px 25px -12px rgba(0, 0, 0, 1) !important;
                box-shadow: 0px 0px 25px -12px rgba(0, 0, 0, 1) !important;
            }

            &.user_points {
                border-bottom: 1px solid #e4e4e4;
                padding-bottom: 5px;
            }

            &.user_details {
                // transition: all 300ms ease;
                text-align: center;
                cursor: pointer;
                float: right;

                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                supported by Chrome and Opera */

                display: inline-block;
                position: relative;
                height: 45px;
                width: 35px;

                fill: rgba(255, 255, 255, 0.5);
                margin-top: 24px;

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    margin-top: 17px;
                }

                @media only screen and (min-width: 460px) {
                    margin-left: 5px;
                }

                svg {
                    max-width: 25px;
                }

                &:hover {
                    fill: rgba(255, 255, 255, 1);
                }

                &.disableClicks{
                    pointer-events: none;
                }

                img.tier {
                    transition: all 300ms ease;
                    height: 20px;
                    float: left;
                    clear: both;

                    @media only screen and (max-width: 960px) {
                        // display: none;
                    }
                }

                &:after {
                    transition: all 300ms ease;
                    font-family: FontAwesome;
                    text-decoration: inherit;
                    font-weight: normal;
                    font-style: normal;
                    content: '\f078';

                    display: inline-block;
                    pointer-events: none;
                    position: absolute;
                    cursor: pointer;
                    color: #fff;

                    right: 20px;
                    top: 27px;

                    display: none;
                }

                b {
                    transition: all 300ms ease;
                    display: inline-block;
                    margin-top: 10px;
                    font-size: 18px;
                    color: $gold;
                    float: right;
                    width: auto;

                    &.name {
                        text-align: left;
                        width: 100%;
                        float: left;
                        margin: 0;

                        margin-bottom: 10px;
                        color: #000;
                    }

                    &.points {
                        text-transform: uppercase;
                        font-weight: bold;
                        text-align: left;
                        font-size: 14px;
                        color: #000;
                        width: auto;
                        margin: 0;

                        margin-top: 5px;
                    }

                    @media only screen and (max-width: 960px) {
                        // display: none;
                    }
                }

                @media only screen and (max-width: 960px) {
                    padding-left: 0;
                    // height: 75px;

                    b {
                        margin-top: 23px;
                    }
                }

                @media only screen and (max-width: 460px) {
                }

                ul.user_menu {
                    transition: all 300ms ease;
                    display: inline-block;
                    list-style: none;
                    background: #fff;
                    text-align: left;
                    padding: 15px;
                    margin: 0;

                    li {
                        display: inline-block;
                        margin-bottom: 2px;
                        width: 100%;

                        a {
                            text-transform: uppercase;
                            display: inline-block;
                            font-size: 15px;
                            color: #a7a7a7;
                            width: 100%;
                            padding: 0;

                            &:hover {
                                text-decoration: none;
                                color: $gold;
                            }
                        }

                        &.mobile_only {
                            display: none;

                            @media only screen and (max-width: 960px) {
                                border-bottom: 1px solid $gold;
                                display: inline-block;
                                padding-bottom: 10px;

                                img.tier {
                                    display: inline-block !important;
                                    float: left;
                                }

                                .user_points {
                                    border-bottom: 1px solid #d2d2d2;
                                    transition: all 300ms ease;
                                    display: inline-block;
                                    padding-bottom: 5px;
                                    margin-bottom: 5px;
                                    text-align: left;
                                    margin-top: 0;
                                    float: left;
                                    width: 100%;

                                    b {
                                        position: relative;
                                        font-weight: bold;
                                        margin-top: 0;
                                        font-size: 20px;
                                        float: left;
                                        top: -6px;

                                        display: inline-block;
                                    }

                                    @media only screen and (max-width: 520px) {
                                        display: inline-block;

                                        b {
                                            display: inline-block !important;
                                            margin-right: 10px;
                                        }
                                    }
                                }

                                span {
                                    &.rank,
                                    &.type {
                                        display: inline-block;
                                    }

                                    &.rank {
                                        left: 15px;
                                    }
                                }
                            }
                        }
                    }
                }

                // Active
                &.active {
                    // background: #fff;

                    &:after {
                        // color: #b1b1b1;
                    }

                    ul.user_menu {
                        display: inline-block;
                        opacity: 1;

                        &:before {
                            @include rotate(45deg);
                            display: inline-block;
                            position: absolute;
                            background: #fff;
                            height: 15px;
                            width: 15px;

                            right: 10px;
                            top: -3px;

                            content: '';
                        }
                    }
                }
            }
        }
    }

    // Rank
    span {
        transition: all 300ms ease;

        &.rank {
            border: 1px solid #848484;
            @include border-radius(50%);
            display: inline-block;
            position: relative;
            height: 10px;
            width: 10px;
            float: left;
            clear: both;
            left: 0px;
            top: 8px;

            @media only screen and (max-width: 960px) {
                display: none;
            }

            &:before {
                border: 1px solid #848484;
                @include border-radius(50%);
                display: inline-block;
                position: absolute;
                height: 10px;
                width: 10px;
                content: '';
                left: -14px;
                top: -1px;
            }

            &:after {
                border: 1px solid #848484;
                @include border-radius(50%);
                display: inline-block;
                position: absolute;
                height: 10px;
                width: 10px;
                content: '';
                right: -14px;
                top: -1px;
            }

            &.tier1 {
                &:before {
                    background: #dfc45e;
                    border-color: #dfc45e;
                }
            }

            &.tier2 {
                background: #dfc45e;
                border-color: #dfc45e;

                &:before {
                    background: #dfc45e;
                    border-color: #dfc45e;
                }
            }

            &.tier3 {
                background: #dfc45e;
                border-color: #dfc45e;

                &:before {
                    background: #dfc45e;
                    border-color: #dfc45e;
                }

                &:after {
                    background: #dfc45e;
                    border-color: #dfc45e;
                }
            }
        }

        &.type {
            transition: all 300ms ease;
            font-family: 'RRP Font v5';
            text-transform: uppercase;
            display: inline-block;
            position: relative;
            margin-left: 35px;
            color: #dfc45e;
            float: right;
            top: 0px;

            @media only screen and (max-width: 960px) {
                display: none;
            }
        }
    }
}
