.fdaSection {
    display: flex;
    margin: 100px auto;
    width: 100%;
    max-width: 1026px;
    padding: 30px 0 15px 30px;
    background: #2AD1D2;
    background-size: cover;
    background-position: center;

    @media only screen and (max-width: 820px) {
        margin: 20px auto;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 30px;
    }
    .leftSection {
        p {
            text-align: left;
            color: #333333;
            font-weight: 400;

            @media only screen and (max-width: 1160px) {
                width: 100%;
                float: none;
            }

            @media only screen and (max-width: 728px) {
                float: none;
            }
        }
        .mobileDevice {
            display: none;
            @media only screen and (max-width: 768px) {
                display: block;
                margin: 20px auto;
                float: none;
            }
        }
        img {
            display: inline-block;
            float: right;
            max-width: 40%;
            margin-bottom: -40px 0;

            @media only screen and (max-width: 1160px) {
                max-width: 100%;
            }
            @media only screen and (max-width: 768px) {
                margin-right: -30px;
            }
        }
        button {
            float: left;

            @media only screen and (max-width: 1160px) {
                float: left;
                margin: 0 auto;
            }

            @media only screen and (max-width: 768px) {
                float: none;
                margin: 20px auto;
                display: block;
                width: 100%;
            }
        }

        .importantInfo {
            padding-right: 30px;
            margin-top: 15px;

            @media only screen and (max-width: 1160px) {
                padding: 0;
            }

            p {
                width: 100%;
                display: block;
                padding: 18px 0 18px 18px;
                border: 1px solid #000000;
                font-size: 16px;
                color: #333333;
                font-weight: 500;
                background-color: rgba(255, 255, 255, 0.7);
            }
            span {
                font-size: 14px;
                text-align: left;
                margin-top: 20px;
                opacity: .5;
            }
        }
    }
    .image {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        img {
            max-width: 250px;
        }
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
}