/* =============================================================================
    o   VARIABLES
============================================================================= */

$bg_color: #fff;
$text_color: #1e1e1e;
$link_color: red;

$primary_color: #d6a76f;
$darkerGold: #ba9367;
$darkGold: #ba9367;
$gold: #d6a76f;

// Fonts
$sourcesanspro: 'Source Sans Pro';
$notosans: 'Noto Sans';
$planer: 'Source Sans Pro';

// States
.is-danger {
    color: #c81111;
}

.is-success {
    color: #60a60e;
}

.is-pending {
    color: #767676;
}
