.container{
    position: relative;
    text-align: center;
    max-width: 1240px;
    margin: 0 auto;
    width: 90%;

    .accordion{
        margin-top: 35px;
        width: 100%;
    }

    .toggler{
        display: inline-block;
        position: relative;
        width: 100%;

        &:after{
            display: inline-block;
            position: absolute;
            cursor: pointer;
            content: '+';

            right: 0;
            top: 12px;

            font-weight: bold;
            font-size: 30px;

            @media only screen and (max-width: 460px) {
                top: 0;
            }
        }

        h3{
            border-top: 1px solid #f2f2f2;
            font-weight: 700;
            font-size: 22px;
            color: #312e2e;
            text-align: left;
            cursor: pointer;
            padding: 20px;
            outline: none;
            float: left;
            width: 100%;

            @media only screen and (max-width: 460px) {
                padding: 10px 10px 10px 0;
                line-height: normal;
                font-size: 18px;
            }
        }
    }

    .activeToggler{
        @extend .toggler;

        &:after{ content: '-' }
    }

    .collapse{
        text-align: left;
        padding: 0 20px;
        width: 100%;

        > div{
            max-width: 100%;

            img{
                max-width: 100%;
            }
        }

        @media only screen and (max-width: 460px) {
            padding: 0;
        }

        p{
            text-align: left;
        }
    }
}
