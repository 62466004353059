/* =============================================================================
    o   CONTENT
============================================================================= */

.content {
    .page_heading {
        display: inline-block;
        margin-bottom: 50px;
        position: relative;
        padding: 50px 0;
        width: 100%;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0a0a14+0,313140+50,0a0a14+100 */
        background: #0a0a14; /* Old browsers */
        background: -moz-linear-gradient(left, #0a0a14 0%, #313140 50%, #0a0a14 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #0a0a14 0%, #313140 50%, #0a0a14 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to right,
            #0a0a14 0%,
            #313140 50%,
            #0a0a14 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a0a14', endColorstr='#0a0a14',GradientType=1 ); /* IE6-9 */

        &.mapPage{
            margin-bottom: 0;
        }

        &.isReferral{
            //background-image: url(../../../assets/images/backgrounds/header-referral.png);
            width: 100%;
            height: 60vh;
            background-size: cover;
            background-position: center;
            position: relative;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;

            @media only screen and (max-width: 768px) {
                //background-image: url(../../../assets/images/backgrounds/header-referral-mobile.png);
            }

            &:after{
                background: rgba(0,0,0, 0.2);
                position: absolute;
                height: 100%;
                width: 100%;
                content: '';
                left: 0;
                top: 0;
            }
            .rowHeading {
                display: flex;
                height: 60vh;
                margin-top: -50px;
                @media only screen and (max-width: 780px) {
                    flex-flow: column-reverse;
                }
                .textContainer{
                    display: flex;
                    justify-self: center;
                    flex-direction: column;
                    align-self: center;
                    height: 100%;
                    justify-content: center;
                    width: 50%;
                    background: #2AD1D2;
                    @media only screen and (max-width: 780px) {
                        width: 100%;
                    }
                    .detailsHeader {
                        display: flex;
                        flex-flow: column;
                        text-align: left;
                        max-width: 60%;
                        margin: 0 auto;
                        padding-left: 110px;
                        @media only screen and (max-width: 1300px) {
                            max-width: 90%;
                            padding-left: 40px;
                        }
                        @media only screen and (max-width: 780px) {
                            padding-left: 0;
                            text-align: center;
                            max-width: 80%;
                        }
                        h2 {
                            color: #34303D;
                            font-size: 36px;
                            font-weight: 500;
                            padding: 0;
                            &:nth-of-type(2) {
                                color: #ffffff;
                            }
                            @media only screen and (max-width: 780px) {
                                font-size: 28px;
                            }
                        }
                        p {
                            color: #ffffff;
                            font-size: 21px;
                            padding: 0;
                            font-weight: 400;
                            @media only screen and (max-width: 780px) {
                                font-size: 18px;
                            }
                        }
                    }

                    button{
                        width: fit-content;
                        margin: 15px auto;
                    }

                    &:first-child{
                        font-weight: normal;
                    }
                }
                .imageHeader {
                    width: 50%;
                    background-image: url(../../../assets/images/backgrounds/mgmHeading.png);
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    @media only screen and (max-width: 780px) {
                        width: 100%;
                        height: 100%;
                    }
                }
            }



            h1,h2{
                font-family: 'Source Sans Pro';
                text-transform: none;
                margin: 0;
                padding: 0 10px;
            }

            h1 {
                font-weight: normal;
                @media screen and (max-width: 550px){
                    font-size: 20px;
                }
            }

            h2 {
                font-weight: bold;
                color: #fff;
                @media screen and (max-width: 550px){
                    font-size: 24px;
                }
            }

            .darker-brown, .light-brown{
                width: 100%;
                height: 48px;
                background-color: #E4C077;

                @media only screen and (max-width: 570px) {
                    height: 30px;
                }
            }

            .darker-brown{
                position: relative;
                opacity: .8;

                &:after{
                    // content: "*adul\21Bi fum\103tori";
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    height: 47px;
                    background-color: rgba(288, 192, 119, 0.8);
                    font-size: 14px;
                    right: 0;
                    color: #fff;
                    line-height: 43px;
                    text-align: right;
                    padding: 0 20px 0 0;
                }
            }
            .light-brown {  opacity: .4; height: 24px; }

            div, h1 {
                z-index: 1;
            }
        }

        &.isPWA{
            background: #1F202B;
            margin-bottom: 0;

            -webkit-box-shadow: 0px 4px 17px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px 4px 17px 0px rgba(0,0,0,0.2);
            box-shadow: 0px 4px 17px 0px rgba(0,0,0,0.2);
           
        }

        @media only screen and (max-width: 570px) {
            padding: 35px 0;
        }

        @media only screen and (max-width: 360px) {
            br{ display: none; }
        }

        h1 {
            text-transform: uppercase;
            font-family: 'Noto Sans';
            font-weight: bold;
            //max-width: 640px;
            font-size: 36px;
            color: #fff;
            padding: 0 10px;
            margin: 0;

            @media only screen and (max-width: 720px) {
                font-size: 32px !important;
            }
        }

        p {
            font-weight: 300;
            font-size: 16px;
            color: #87888e;

            max-width: 720px;
            padding: 0 25px;
            margin: 0 auto;
        }

        .points {
            text-transform: uppercase;
            background: $darkerGold;
            font-family: $planer;
            text-align: center;
            position: absolute;
            font-size: 18px;
            width: 210px;
            color: #fff;

            left: calc((100% - 210px) / 2);
            bottom: -25px;

            @include border-radius(5px);
            padding: 0 10px;

            b {
                font-weight: 500;
                font-size: 36px;
            }

            span {
                text-transform: none;
                font-weight: 500;
                font-size: 21px;
                padding: 10px 0;
            }
        }

        &.capacelEdition {
            padding-bottom: 0;

            h1 {
                max-width: none;

                @media only screen and (max-width: 640px) {
                    padding: 0 25px;
                }
            }

            h2 {
                margin-top: 15px;
                max-width: 780px;
                font-size: 28px;
                color: #fff;

                @media only screen and (max-width: 640px) {
                    padding: 0 25px;
                    font-size: 18px;
                }
            }

            img {
                margin-top: 50px;
                position: relative;
                bottom: -6px;

                @media only screen and (max-width: 810px) {
                    max-width: 80%;
                }
            }

            .scrollDown {
                display: inline-block;
                position: absolute;
                height: 100px;
                width: 100px;

                left: calc((100% - 100px) / 2);
                bottom: -50px;

                @include border-radius(50%);

                background-image: url('../../../assets/images/ui/scrollDown.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-color: #fff;
                background-size: auto;
            }
        }

        // Sub Heading
        &.hasSubheading {
            padding-bottom: 0;
            margin-bottom: 0;

            .sub_heading {
                display: inline-block;
                background: #000;
                margin-top: 50px;
                width: 100%;
                float: left;
                
                .isReferral{
                    margin-top: 0;
                    z-index:1;
                    @media only screen and (max-width: 570px) { margin-top: 0; }
                }

                @media only screen and (max-width: 570px) { margin-top: 35px; }

                ul {
                    display: inline-block;
                    text-align: center;
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    width: 100%;
                    float: left;

                    li {
                        display: inline-block;

                        a {
                            text-transform: uppercase;
                            display: inline-block;
                            letter-spacing: 1px;
                            padding: 12px 0;
                            margin: 0 15px;

                            @media only screen and (max-width: 460px) {
                                margin: 0 5px;
                            }

                            @media only screen and (max-width: 360px) {
                                font-size: 12px;
                            }

                            &:hover {
                                color: #fff;
                            }

                            &.isActive {
                                position: relative;
                                color: #fff;

                                @media only screen and (min-width: 320px) {
                                    &:after {
                                        @include rotate(45deg);
                                        display: inline-block;
                                        position: absolute;
                                        background: #fff;
                                        height: 15px;
                                        width: 15px;

                                        content: '';
                                        bottom: -12px;
                                        left: calc((100% - 15px) / 2);
                                    }
                                }
                            }
                        }

                        &.otherSubHeading {
                            a {
                                &:after {
                                    background: $gold;
                                }
                            }
                        }
                    }
                }

                p.customHeading {
                    //background: #e5e5e5;
                    background: $gold;
                    padding: 20px 0;
                    font-size: 18px;
                    color: #fff;
                    width: 100%;
                    left: 0;

                    @media only screen and (max-width: 780px) {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.oneYear {
        .page_heading {
            background-image: url('../../../assets/images/ui/page-headings/one-year-bg.png');
            background-position: top center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-color: #0c0b1e;

            padding: 200px 0 100px;
            margin-bottom: 0;
            margin-top: 70px;

            @media only screen and (max-width: 460px) {
                background-image: url('../../../assets/images/ui/page-headings/one-year-bg2.png');
                background-size: cover;
                min-height: 55vh;
            }

            h1 {
                margin-top: 50px;
                line-height: 0px;
                font-size: 25px;
                color: $gold;

                @media only screen and (max-width: 460px) {
                    margin-top: 200px;
                }
            }

            p {
                text-transform: uppercase;
                line-height: normal;
                letter-spacing: 3px;
                font-size: 21px;
                color: #fff;
            }
        }
    }
}

.isPWA{
    .content{
        .page_heading{
            background: #1F202B;
        }
    }
}
