.header {
    .coachmarks {
        position: absolute;
        width: 300px;
        top: 125px;

        right: 0px;

        @media only screen and (min-width: 560px) {
            text-align: left;
            right: 250px;
        }

        @media only screen and (max-width: 560px) {
            top: 300px;
        }

        @media only screen and (max-width: 500px) {
            width: calc(100% - 50px);
            margin: 0 25px;
        }

        p {
            color: #fff;

            &:before {
                @include font-awesome();
                content: '\f176';
                display: block;

                @media only screen and (min-width: 560px) {
                    @include rotate(90deg);
                    position: absolute;
                    right: 0;
                }
            }
        }

        a.primary_button {
            border: 1px solid rgba(255, 255, 255, 0.3);
            background: transparent;
            line-height: 36px;

            &:hover {
                background: $gold;
            }
        }

        a.go_back {
            border: none;

            &:hover {
                background: none;
            }

            &:before {
                color: #fff;
            }
        }
    }

    &.showCoachmarks {
        position: absolute;

        ul.menu,
        .account_details {
            pointer-events: none;
        }

        .coachmarks {
            display: inline-block;
        }

        .notificationsBar {
            display: none;
        }
    }
}
