/* =============================================================================
    o   MIXINS
============================================================================= */

/* =============================================================================
    o   FONTFACE
============================================================================= */

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
    @font-face {
        font-family: quote($font-name);
        src: url($file-name+'.eot');
        src: url($file-name+'.eot?#iefix') format('embedded-opentype'), url($file-name+'.woff') format('woff'),
            url($file-name+'.ttf') format('truetype'), url($file-name+'.svg##{$font-name}') format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}

//	@include font-face('gotham', '/fonts/gotham');

/* =============================================================================
    o   ANIMATE
============================================================================= */

@mixin animate($animation, $duration) {
    -webkit-animation-duration: $duration;
    animation-duration: $duration;

    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    -webkit-animation-name: $animation;
    animation-name: $animation;
}

@mixin transition() {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

@mixin rotate($degree) {
    -ms-transform: rotate($degree); /* IE 9 */
    -webkit-transform: rotate($degree); /* Safari */
    transform: rotate($degree);
}

/* =============================================================================
    o   BORDER RADIUS
============================================================================= */

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

// Single side border-radius

@mixin border-top-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    -moz-border-top-right-radius: $radius;
    border-top-right-radius: $radius;

    -webkit-border-top-left-radius: $radius;
    -moz-border-top-left-radius: $radius;
    border-top-left-radius: $radius;

    background-clip: padding-box;
}
@mixin border-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    -moz-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;

    -webkit-border-top-right-radius: $radius;
    -moz-border-top-right-radius: $radius;
    border-top-right-radius: $radius;

    background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    -moz-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;

    -webkit-border-bottom-left-radius: $radius;
    -moz-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;

    background-clip: padding-box;
}
@mixin border-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;

    -webkit-border-top-left-radius: $radius;
    -moz-border-top-left-radius: $radius;
    border-top-left-radius: $radius;

    background-clip: padding-box;
}

/* =============================================================================
    o   LINEAR GRADIENTS
============================================================================= */

@mixin linearGradient($top, $bottom) {
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, $top),
        color-stop(100%, $bottom)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $top 0%, $bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $top 0%, $bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom, $top 0%, $bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

//

@mixin font-awesome() {
    font-family: FontAwesome;
    text-decoration: inherit;
    font-weight: normal;
    font-style: normal;
}
