.optionContainer {
    display: block;
    margin-top: 64px;
    padding: 0;
    border-top: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: -webkit-fill-available;
    overflow: overlay;
    max-height: 90px;
    position: absolute;
    background: white;
    z-index: 1;
    cursor: pointer;

    .option {
        display: flex;
        flex-direction: row;
        padding-left: 15px;
    }
    .option:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
}
