.responsive_table { width: 100%; }

table:not(.ant-picker-content) {
    &:not(.ant-calendar-table){
        border-collapse: collapse;
        border-spacing: 0;
        margin: 25px 0 0;
        max-width: 100%;
        width: 100%;

        th {
            color: #000;
            padding: 10px;
            font-size: 12px;
            text-align: left;
            line-height: normal;
            font-weight: normal;
            background: transparent;
            text-transform: uppercase;
            border-right: 1px solid #fff;
            border-bottom: 1px solid #f4f4f4;

            form {
                label {
                    margin-bottom: 0 !important;

                    input,
                    select {
                        border-bottom: none !important;
                        padding-left: 0 !important;
                    }
                }
            }

            a.sort {
                display: inline-block;
                text-align: right;
                font-size: 18px;
                float: right;
                height: 25px;
                width: 25px;
                color: #fff;

                &:before {
                    @include font-awesome();
                    content: '\f07d';
                }

                &:hover {
                    text-decoration: none;
                }

                @media only screen and (max-width: 930px) {
                    display: none;
                }
            }
        }

        tr {
            margin: 0;
            padding: 0;
            border: none;
            border-bottom: 1px solid #f4f4f4;

            td {
                margin: 0;
                border: none;
                padding: 15px;
                font-size: 16px;
                text-align: left;
                font-weight: bold;
                line-height: normal;
                text-transform: uppercase;
                border-right: 1px solid #d4d4d4;
                border-bottom: 1px solid #f4f4f4;

                &:first-child {
                    @media screen and (min-width: 840px) {
                        min-width: 250px;
                    }
                }

                &:last-child {
                    border-right: none;
                }
            }
        }

    }

    &:not(.noStyle){
        tbody {
            tr {
                &:nth-child(odd) {
                    background: #f1f1f1;
                }
            }
        }
    }
}

@media screen and (max-width: 840px) {
    table:not(.ant-calendar-table) {
        border: 0;
    }

    table:not(.ant-calendar-table) caption {
        font-size: 16px;
    }

    table:not(.ant-calendar-table) thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table:not(.ant-calendar-table) tr {
        border-bottom: 1px dotted #9E9E9F;
        margin-bottom: 0.625em;
        display: block;

        &:nth-child(odd){ background: none !important; }

        td {
            display: inline-block;
            text-align: right;
            font-size: 16px;
            width: 100%;

            padding: 10px 10px 0px;
            text-transform: none;
            font-weight: normal;
            border: none;

            &:nth-child(odd){ background: #f4f4f4; }

            @media screen and (max-width: 440px) {
                font-size: 13px;
            }

            &:before {
                /*
			    * aria-label has no advantage, it won't be read inside a table
			    content: attr(aria-label);
			    */
                content: attr(data-label);
                text-transform: uppercase;
                margin-bottom: 10px;
                font-weight: bold;
                float: left;
            }
        }
    }

    table:not(.ant-calendar-table) td:last-child {
        border-bottom: 0;
    }
}
