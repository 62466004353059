/* =============================================================================
	o   PRELOADER
============================================================================= */

.bg-overlay {
    background: rgba(0, 0, 0, 0.95);
    position: fixed;
    display: none;
    z-index: 5;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}

.preloader {
    background: rgba(255, 255, 255, 1);
    position: fixed;
    display: none;
    z-index: 5;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    .loader {
        display: inline-block;
        //background: url('../../assets/images/ui/loader.gif') center center #fff no-repeat;
        @include border-radius(50%);
        position: fixed;
        padding: 10px;
        height: 94px;
        width: 94px;
        z-index: 4;

        top: calc((100% - 94px) / 2);
        left: calc((100% - 94px) / 2);
    }

    span {
        display: inline-block;
        text-align: center;
        font-weight: 700;
        position: fixed;
        width: 100%;
        color: #000;
        top: 57%;
        left: 0;
    }
}

/* =============================================================================
	o   ANIMATION
============================================================================= */

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
