#acc_regulament {
    width: 100%;

    .accordion_search_container {
        width: 100%;
        max-width: 540px;
        display: block;
        position: relative;

        padding: 20px 0;
        margin: 0 auto;

        &:after {
            content: 'search';
            position: absolute;
            font-family: 'Material Icons';
            font-size: 30px;
            right: 0;
        }

        input {
            width: 100%;
            padding: 15px 0;
            border: none;
            border-bottom: 1px solid $gold;
            outline: none;

            font-size: 16px;
            font-family: 'Source Sans Pro';
        }
    }
}

section {
    display: block;
    text-align: left;
    overflow: hidden;
    background: #FFF;

    h2 {
        font-size: 22px;
        color: #000;
        font-weight: 700;
        padding: 20px 0;
        z-index: 1;
        background: #FFF;
        position: relative;
        text-decoration: underline;
        cursor: pointer;

        a {color: #000;}
    }

    h3 {
        font-size: 18px;
        padding-left: 100px;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;
        background-color: #FFF;
        z-index: 1;
        margin: 0;

        @media only screen and (max-width: 768px) {
            padding-left: 0;
            text-align: left;
        }

        a {
            color: #000;
            text-decoration: underline;

            &:before {
                content: 'panorama_fish_eye';
                font-family: "Material Icons";
                font-size: 9px;
                position: absolute;
                left: 25px;
                top: 17px;

                @media only screen and (max-width: 768px) {
                    content: '';
                }
            }
        }
    }

    > h4 {
        width: 100%;
        margin: 0;
        padding: 10px 0 12px 75px;
        color: #000;
        background-color: #FFF;
        z-index: 1;
        font-size: 18px;
        font-weight: 400;
        text-align: justify;
        cursor: pointer;
        position: relative;

        @media only screen and (max-width: 768px) {
            padding: 10px 0 12px 0;
            text-align: left;
        }
    }

    .acc_container {
        .block {
            text-align: left;
            background-color: #FFF;
            z-index: -1;

            h2 {
                font-size: 22px;
                color: #312e2e;
                font-weight: 700;
                padding: 20px 0;
                z-index: 1;
                background: #FFF;
                position: relative;
                text-decoration: underline;
            }

            h3 {
                font-size: 18px;
                padding-left: 50px;
                padding-top: 10px;
                padding-bottom: 10px;
                position: relative;
                background-color: #FFF;
                z-index: 1;
                margin: 0;

                @media only screen and (max-width: 420px) {
                    padding-left: 0px;
                }
            }

            h4 {
                width: 100%;
                margin: 0;
                padding: 10px 0 12px 75px;
                color: #000;
                background-color: #FFF;
                z-index: 1;
                font-size: 18px;
                font-weight: 400;
                text-align: justify;
                cursor: pointer;
                position: relative;

                -webkit-transition: all 300ms ease;
                transition: all 300ms ease;

                @media only screen and (max-width: 420px) {
                    padding-left: 35px;
                    text-align: left;
                }

                &:hover {
                    margin-left: 10px;
                    transition: all 300ms ease;
                    -webkit-transition: all 300ms ease;
                }

                a{
                    color: #000;
                }
            }

            p {
                margin: 0;
                color: #838383;
                font-size: 18px;
                text-align: justify;
                padding: 10px 0;

                b {
                    color: #000;
                    font-weight: 400;
                }
            }

            ul {
                padding: 10px 0 10px 17px;
                margin: 0;

                &.dashLine {
                    padding: 5px 0 5px 17px;
                    margin: 0;
                    display: block;

                    li {
                        display: block;
                        position: relative;
                        padding: 10px 0 5px 10px;

                        list-style-type: none;
                        text-align: left;

                        &:before {
                            content: '-';
                            font-weight: 100;
                            position: absolute;
                            left: -10px;
                            top: 7px;
                        }
                    }
                }

                li {
                    padding: 10px 0 5px 20px;
                }
            }

            ol {
                padding: 10px 0 10px 17px;
                margin: 0;
                text-align: justify;

                li {
                    padding: 10px 0 5px 20px;
                }
            }

            span {
                display: block;
                padding: 10px 0 10px 160px;

                a {
                    font-size: 18px;
                    color: #000;
                }
            }
        }
    }
}

.regulamentPDF {
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 10px 0 12px 150px;
    color: #000;
    background-color: #fff;
    z-index: 1;
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    cursor: pointer;
    position: relative;
    float: left;

    span {
        padding: 0 !important;
        float: left !important;

        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;

        &:hover {
            margin-left: 10px;
            transition: all 300ms ease;
            -webkit-transition: all 300ms ease;
        }
    }
}
